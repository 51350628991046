import React from 'react';
import { RouteComponentProps } from 'react-router';
import { TariffType } from '../../../@types/model/transformer/tariffs';
import Toolbar from '@material-ui/core/Toolbar';
import Card from '@material-ui/core/Card';
import { ITransformerInvoice, TransformerInvoiceHelper } from '../../../@types/model/transformer/transformerInvoice';
import MaterialTable from '../../customComponents/materialTable/Table';
import AppFunctionsService from '../../../services/appFunctionsService';
import Tooltip from '@material-ui/core/Tooltip';
import StandardFab from '../../customComponents/button/StandardFab';
import AddIcon from '@material-ui/icons/Add';
import moment from 'moment';
import { DatePicker } from '@material-ui/pickers';
import FormControl from '@material-ui/core/FormControl';
import Icon from '@material-ui/core/Icon';
import InputAdornment from '@material-ui/core/InputAdornment';
import TransformerDropdown from '../../customComponents/selector/transformer/TransformerSelector';
import { ITransformer } from '../../../@types/model/transformer/transformer';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import DropdownButton from '../../customComponents/button/DropdownButton';
import generalFunctions from '../../../store/general/functions';
import lodash from 'lodash';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import TransformerInvoiceJournalDialog from './dialog/JournalDialog';
import { Transitions } from '../../customComponents/animations/Transitions';
import { createSelector } from 'reselect';
import { IUserSession } from '../../../@types/employee';
import { IRootState } from '../../../@types/redux';
import { connect } from 'react-redux';
import TransformerGroupDropdown from '../../customComponents/selector/transformer/GroupSelector';
import { ITransformerGroup } from '../../../@types/model/transformer/transformerGroup';
import TransformerEditDialog from './dialog/InvoiceEditDialog';
import GeneralFunctions from '../../../store/general/functions';
import DeleteIcon from '@material-ui/icons/Delete';
import ProjectDialog from '../../customComponentV2/dialog/ProjectDialog';
import LPUCapture from './LPUCapture';
import ConfirmationDialog from '../../customComponentV2/dialog/ConfirmationDialog';
import SPUCapture from './SPUCapture';

interface ITransformerCaptureListProps extends RouteComponentProps<Record<string, string | undefined>, Record<string, unknown>, {
    type : TariffType;
}> {
    session ?: IUserSession | null;
}

interface ITransformerCaptureListState {
    type ?: TariffType;
    isLoading : boolean;
    invoices : Array<ITransformerInvoice>;

    monthDate : moment.Moment | null;
    accountNumber : string;
    epNumber ?: string;
    group ?: string;

    selectedInvoices : Array<ITransformerInvoice>;
    invoiceToDelete : ITransformerInvoice | null;

    isLPUCaptureDialogOpen : boolean;
    isSPUCaptureDialogOpen : boolean;
}

class TransformerCaptureListComponent extends React.PureComponent<ITransformerCaptureListProps, ITransformerCaptureListState> {
    private readonly maxDate = moment.utc().startOf('month');
    private listen ?: () => void;
    constructor(props : ITransformerCaptureListProps) {
        super(props);
        this.state = {
            isLoading: false,
            invoices: [],
            monthDate: null,
            accountNumber: '',
            selectedInvoices: [],
            invoiceToDelete: null,
            isLPUCaptureDialogOpen: false,
            isSPUCaptureDialogOpen: false,
        };
    }

    public readonly componentDidMount = () => {
        this.setType();
    };

    public readonly componentWillUnmount = () => {
        if (this.listen) this.listen();
    };

    public readonly componentDidUpdate = (prevProps : ITransformerCaptureListProps, prevState : ITransformerCaptureListState) => {
        if (prevProps.location.search !== this.props.location.search) this.setType();

        if (prevState.type !== this.state.type) this.loadList();
    };

    private readonly setType = () => {
        const urlParams = new URLSearchParams(this.props.location.search);
        const type = urlParams.get('type') as TariffType;

        this.setState({
            type,
            invoices: [],
        });
    };

    private readonly loadList = () => {
        if (!this.state.type) return;
        if (this.listen) this.listen();
        const session = this.props.session;

        if (!session) return;

        const urlParams = new URLSearchParams(this.props.location.search);
        const type = urlParams.get('type') as TariffType;

        this.listen = TransformerInvoiceHelper.collection().where('type', '==', type).onSnapshot(snapshot => {
            const invoices = this.state.invoices.slice();

            try {
                snapshot.docChanges().forEach((f) => {
                    const invoice = f.doc.data();
                    if (
                        !session.employee.IsTransformerAdmin &&
                        invoice.transformer.Division &&
                        session.employee.Areas.length &&
                        !session.employee.Areas.includes(invoice.transformer.Division)) return;

                    const index = lodash.findIndex(invoices, n => n.id === f.doc.id);
    
                    switch (f.type) {
                        case 'added':
                            invoices.push(invoice);
                            break;
                        case 'modified':
                            invoices.splice(index, 1, invoice);
                            break;
                        case 'removed':
                            invoices.splice(index, 1);
                            break;
                    }
                });
            } catch (err) {
                generalFunctions.generalShowError(err, 'Error loading invoices');
            }

            this.setState({
                isLoading: false,
                invoices: invoices.sort((a, b) => b.monthDate - a.monthDate),
            });
        }, (err) => {
            generalFunctions.generalShowErrorSnackbar(err.message);
            this.setState({
                isLoading: false,
                invoices: [],
            });
        });
    };

    private readonly onAddClick = () => {
        if (this.state.type === 'lpu') {
            this.setState({
                isLPUCaptureDialogOpen: true,
            });
        } else if (this.state.type === 'spu') {
            this.setState({
                isSPUCaptureDialogOpen: true,
            });
        }
    };

    private readonly onMonthDateChanged = (momentDate : moment.Moment | null) => {
        this.setState({
            monthDate: !momentDate ? null : moment.utc(momentDate.valueOf()).startOf('month'),
            selectedInvoices: [],
        });
    };

    private readonly onClearDateClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        this.setState({
            monthDate: null,
            selectedInvoices: [],
        });
    };

    private readonly onEPNumberChange = (value ?: ITransformer) => {
        this.setState({
            epNumber: value?.EPNumber,
            selectedInvoices: [],
        });
    };

    private readonly onAccountNumberChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            accountNumber: event.currentTarget.value,
            selectedInvoices: [],
        });
    };

    private readonly onGroupChange = (value ?: ITransformerGroup | undefined) => {
        this.setState({
            group: value?.name,
            selectedInvoices: [],
        });
    };

    private readonly onJournalDialogClose = () => {
        this.setState({
            selectedInvoices: [],
        });
    };

    private readonly onInvoiceDelete = (row : ITransformerInvoice) => {
        this.setState({
            invoiceToDelete: row,
        });
    };

    private readonly handleInvoiceDelete = async () => {
        if (!this.props.session?.employee) return;

        if (this.state.invoiceToDelete) {
            this.setState({
                isLoading: true,
            });

            const inactiveInvoice = TransformerInvoiceHelper.createInactiveSave(this.props.session, this.state.invoiceToDelete);

            try {
                await TransformerInvoiceHelper.saveMany([inactiveInvoice]);
                GeneralFunctions.generalShowSuccessSnackbar(`${inactiveInvoice.transformer.MeterType} Invoice inactivated successfully.`);
            } catch (e) {
                GeneralFunctions.generalShowErrorSnackbar(`An error occurred while inactivating ${inactiveInvoice.transformer.MeterType} Invoice.`);
            } finally {
                this.setState({
                    isLoading: false,
                });
            }
        }

        this.setState({
            invoiceToDelete: null,
        });
    };

    private readonly onCloseLPUCaptureDialog = () => {
        this.setState({
            isLPUCaptureDialogOpen: false,
        });
    };

    private readonly onCloseSPUCaptureDialog = () => {
        this.setState({
            isSPUCaptureDialogOpen: false,
        });
    };

    private readonly getData = (props : ITransformerCaptureListProps, state : ITransformerCaptureListState) => state.invoices;
    private readonly getMonthDate = (props : ITransformerCaptureListProps, state : ITransformerCaptureListState) => state.monthDate;
    private readonly getAccountNumber = (props : ITransformerCaptureListProps, state : ITransformerCaptureListState) => state.accountNumber;
    private readonly getEpNumber = (props : ITransformerCaptureListProps, state : ITransformerCaptureListState) => state.epNumber;
    private readonly getGroup = (props : ITransformerCaptureListProps, state : ITransformerCaptureListState) => state.group;


    public readonly getFilteredData = createSelector(
        [this.getData, this.getMonthDate, this.getAccountNumber, this.getEpNumber, this.getGroup],
        (data, monthDate, accountNumber, epNumber, group) => {
            return lodash
                .chain(data)
                .filter(n => n.accountNumber.toLowerCase().includes(accountNumber.toLowerCase()))
                .filter(n => epNumber ? n.epNumber.toLowerCase().includes(epNumber.toLowerCase()) : true)
                .filter(n => !group || n.group.toLowerCase().includes(group.toLowerCase()))
                .filter(n => monthDate ? n.monthDate == monthDate.valueOf() : true)
                .value();
        },
    );

    private readonly onSelectionChange = (selectedRows : Array<ITransformerInvoice>) => {
        this.setState({
            selectedInvoices: selectedRows,
        });
    };

    public readonly render = () => {
        const { group, isLoading, monthDate, accountNumber, epNumber,
            selectedInvoices, type } = this.state;
        
        const sortedData = this.getFilteredData(this.props, this.state);

        return (
            <div className={'fdc flx1 p10 mh0 mw0 bcg0'}>
                <div className={'flx1 fdc mh0 mw0'}>
                    <Toolbar disableGutters>
                        <div className='flx1 ais mb10'>
                            {
                                !!selectedInvoices.length &&
                                !!type &&
                                <TransformerInvoiceJournalDialog
                                    onClose={this.onJournalDialogClose}
                                    invoices={selectedInvoices}
                                    fullScreen
                                    transition={Transitions.Down}
                                    type={type}
                                />
                            }
                        </div>
                        <div className='fdr mw900 ais'>
                            <div className={'flx1 ais mb10 pr10'}>
                                <FormControl fullWidth>
                                    <DatePicker value={monthDate}
                                        views={['month']}
                                        maxDate={this.maxDate}
                                        onChange={this.onMonthDateChanged} format='MMM YYYY' label='Select Account Month...'
                                        id='monthDate'
                                        className={'TextField'}
                                        margin={'normal'}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    {
                                                        monthDate &&
                                                        <IconButton size='small' onClick={this.onClearDateClick}>
                                                            <Icon className='cr'>close</Icon>
                                                        </IconButton>
                                                    }
                                                    <DropdownButton />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormControl>
                            </div>
                            <div className={'flx1 ais mb10 pl10 pr10'}>
                                <FormControl fullWidth>
                                    <TextField
                                        autoComplete='off'
                                        id='accountNumber'
                                        label='Account No'
                                        value={accountNumber}
                                        onChange={this.onAccountNumberChange}
                                        margin='normal'
                                        className={'TextField'}
                                    />
                                </FormControl>
                            </div>
                            <div className={'flx1 ais mb10 pl10 pr10 mt16'}>
                                <FormControl fullWidth>
                                    <TransformerDropdown
                                        value={epNumber}
                                        label='Waypoint No'
                                        fullWidth
                                        onChange={this.onEPNumberChange}
                                        type={type}
                                    />
                                </FormControl>
                            </div>
                            <div className={'flx1 ais mb10 pl10 pr10 mt16'}>
                                <TransformerGroupDropdown
                                    fullWidth
                                    value={group}
                                    onChange={this.onGroupChange}
                                    type={type?.toLocaleUpperCase()}
                                    disabled={isLoading}
                                    paymentOnly
                                />
                            </div>
                        </div>
                    </Toolbar>
                    <Card className={'flx1 fdc mb70'}>
                        <MaterialTable<ITransformerInvoice>
                            id='transformerInvoicesTable'
                            data={sortedData}
                            isLoading={isLoading}
                            rowsPerPage={100}
                            externalSort
                            selectable
                            selectedRows={selectedInvoices}
                            onSelectionChanged={this.onSelectionChange}
                            isSelectable={(row) => 
                                !!group &&
                                !row.paid &&
                                !row.journaled &&
                                row.group === group &&
                                !!row.transformer.Company &&
                                !!row.transformer.BankAccount
                            }
                            columns={[{
                                header: '',
                                field: 'monthDate',
                                width: 75,
                                paddingLeft: 15,
                                renderCell: row => (
                                    <div className='aic'>
                                        {
                                            !row.journaled &&
                                            <TransformerEditDialog invoice={row} transition={Transitions.Up} />
                                        }
                                        {
                                            !row.journaled &&
                                            <Tooltip title='Delete'>
                                                <div>
                                                    <IconButton className={`${row.isActive ? 'cr' : ''}`} onClick={row.isActive ? () => this.onInvoiceDelete(row) : undefined} >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </div>
                                            </Tooltip>
                                        }
                                    </div>
                                ),
                            }, {
                                header: 'Month',
                                field: 'monthDate',
                                width: 75,
                                paddingLeft: 15,
                                renderCell: row => (
                                    <div className='aic'>
                                        {AppFunctionsService.formatDateTimeToMonthYearOnly(row.monthDate)}
                                    </div>
                                ),
                            }, {
                                header: 'Way Point',
                                field: 'epNumber',
                                width: 145,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Group',
                                field: 'group',
                                width: 175,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Pole No',
                                field: 'poleNumber',
                                width: 145,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Account No',
                                field: 'accountNumber',
                                width: 145,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Total Usage',
                                field: 'totalUsage',
                                width: 145,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Days',
                                field: 'days',
                                width: 145,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Total Charge',
                                field: 'totalCharge',
                                width: 165,
                                enableSort: true,
                                enableFilter: true,
                                renderCell: row => (
                                    <div className=''>
                                        {(row.totalCharge).toLocaleCurrency()}
                                    </div>
                                ),
                            }, {
                                header: 'Interest',
                                field: 'totalInterest',
                                width: 145,
                                enableSort: true,
                                enableFilter: true,
                                renderCell: row => (
                                    <div className=''>
                                        {row.totalInterest.toLocaleCurrency()}
                                    </div>
                                ),
                            }, {
                                header: 'Credit',
                                field: 'credit',
                                width: 145,
                                enableSort: true,
                                enableFilter: true,
                                renderCell: row => (
                                    <div className=''>
                                        {row.credit.toLocaleCurrency()}
                                    </div>
                                ),
                            }, {
                                header: 'VAT',
                                field: 'vatCharge',
                                width: 145,
                                enableSort: true,
                                enableFilter: true,
                                renderCell: row => (
                                    <div className=''>
                                        {row.vatCharge.toLocaleCurrency()}
                                    </div>
                                ),
                            }, {
                                header: 'Total Due',
                                field: 'totalDue',
                                width: 145,
                                enableSort: true,
                                enableFilter: true,
                                renderCell: row => (
                                    <div className=''>
                                        {(row.totalDue).toLocaleCurrency()}
                                    </div>
                                ),
                            }, {
                                header: 'Total Billed',
                                field: 'totalDue',
                                width: 145,
                                enableSort: true,
                                enableFilter: true,
                                renderCell: row => (
                                    <div className=''>
                                        {(row.totalBilled).toLocaleCurrency()}
                                    </div>
                                ),
                            }, {
                                header: 'Is Active?',
                                field: 'isActive',
                                width: 145,
                                enableSort: true,
                                enableFilter: true,
                                renderCell: n => n.isActive ? (<Icon className='cdg'>check</Icon>) : (<Icon className='cr'>close</Icon>),
                            }, {
                                header: 'Journaled',
                                field: 'journaled',
                                width: 145,
                                renderCell: row => (
                                    <div className='fdc jcc'>
                                        <Tooltip title={!row.journaled ? '' : `${row.journaledByName} - ${row.journaledByEmployeeNumber}`}>
                                            <CheckCircleIcon className={!row.journaled ? 'cg2' : 'cp'} />
                                        </Tooltip>
                                    </div>
                                ),
                            }, {
                                header: 'Paid',
                                field: 'paid',
                                width: 145,
                                renderCell: row => (
                                    <div className='fdc jcc'>
                                        <Tooltip title={!row.paid ? '' : `${row.paidByName} - ${row.paidByEmployeeNumber}`}>
                                            <CheckCircleIcon className={!row.paid ? 'cg2' : 'cp'} />
                                        </Tooltip>
                                    </div>
                                ),
                            }, {
                                header: 'Created By',
                                field: 'createdByName',
                                width: 145,
                                renderCell: row => (
                                    <Tooltip title={row.createdByEmployee}>
                                        <div>
                                            {
                                                row.createdByName
                                            }
                                        </div>
                                    </Tooltip>
                                ),
                            }]}
                        />
                    </Card>
                    <div className='fdr jcfe'>
                        <StandardFab aria-label='add' disabled={isLoading} onClick={this.onAddClick}>
                            <AddIcon />
                        </StandardFab>
                    </div>
                </div>
                <ConfirmationDialog 
                    open={!!this.state.invoiceToDelete}
                    isLoading={this.state.isLoading}
                    title={'Delete Invoice'}
                    description={'Are you sure you want to inactivate the selected entry?'}
                    onAccept={this.handleInvoiceDelete}
                    onClose={() => this.setState({ invoiceToDelete: null })}
                    dialogType='red'
                />
                <ProjectDialog
                    isOpen={this.state.isLPUCaptureDialogOpen}
                    fullScreen
                    isLoadingCircular={isLoading}
                    onClose={this.onCloseLPUCaptureDialog}
                >
                    <LPUCapture
                        onDismiss={this.onCloseLPUCaptureDialog}
                    />
                </ProjectDialog>
                <ProjectDialog
                    isOpen={this.state.isSPUCaptureDialogOpen}
                    fullScreen
                    isLoadingCircular={isLoading}
                    onClose={this.onCloseSPUCaptureDialog}
                >
                    <SPUCapture
                        onDismiss={this.onCloseSPUCaptureDialog}
                    />
                </ProjectDialog>
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        session: state.auth.session,
    };
};

const TransformerSPUMeterList = connect(
    mapStateToProps,
)(TransformerCaptureListComponent);

export default TransformerSPUMeterList;