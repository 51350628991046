import { AccordionDetails, AppBar, CircularProgress, Icon, IconButton, Toolbar, Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import { IUserSession } from '../../../@types/employee';
import DamHelper, { IDam } from '../../../@types/model/dam/dam';
import DamSafetyInspectionHelper, { IDamSafetyInspection } from '../../../@types/model/dam/damSafetyInspection';
import AppFunctionsService from '../../../services/appFunctionsService';
import GeneralFunctions from '../../../store/general/functions';
import EditDamSafetyInspection from '../dialog/inspection/EditDialog';
import { Transitions } from '../../customComponents/animations/Transitions';
import ConfirmationDialog from '../../customComponentV2/dialog/ConfirmationDialog';
import StadiumButton from '../../customComponents/button/StadiumButton';
import CustomAccordion from '../../customComponentV2/accordion/CustomAccordion';

interface IDamSafetyProps {
    dam : IDam;
    isLoading : boolean;
    session : IUserSession;
}

interface IDamSafetyState {
    damSafetyInspections : Array<IDamSafetyInspection>;
    currentDamSafetyInspection ?: IDamSafetyInspection;
    isLoading : boolean;
    isDamSafetyInspectionEditDialogOpen : boolean;
    damSafetyInspectionToDelete : IDamSafetyInspection | null;
}

export default class DamSafety extends React.PureComponent<IDamSafetyProps, IDamSafetyState> {
    private listener ?: () => void;

    constructor(props : IDamSafetyProps) {
        super(props);
        this.state = {
            isLoading: false,
            damSafetyInspections: [],
            isDamSafetyInspectionEditDialogOpen: false,
            damSafetyInspectionToDelete: null,
        };
    }

    public componentWillUnmount() : void {
        if (this.listener) this.listener();
    }

    public componentDidMount() : void {
        this.load();
    }

    public readonly load = () => {
        if (this.listener) this.listener();

        this.setState({
            damSafetyInspections: [],
            isLoading: true,
        });

        this.listener = DamSafetyInspectionHelper.collection()
            .where('damRef', '==', DamHelper.doc(this.props.dam.id))
            .orderBy('date', 'desc')
            .onSnapshot((snapshot) => {
                if (!this.listener) return;
                this.setState({
                    damSafetyInspections: snapshot.docs.map(x => x.data()).filter(x => !!x.documentName),
                    isLoading: false,
                });
            }, (error) => {
                GeneralFunctions.generalShowError(error, 'Error Loading Safety Inspections');
            }, () => {
                this.listener = undefined;
            });
    };

    private readonly onViewClick = (inspection : IDamSafetyInspection) => {
        if (!inspection?.documentURL) return;
        window.open(inspection.documentURL, '_blank');
    };

    private readonly onDamSafetyInspectionDelete = (inspection : IDamSafetyInspection) => {
        this.setState({
            damSafetyInspectionToDelete: inspection,
        });
    };

    private readonly onEditClick = (damSafetyInspection: IDamSafetyInspection) => {
        if (!this.props.dam) return;
        this.setState({
            isDamSafetyInspectionEditDialogOpen: true,
            currentDamSafetyInspection: damSafetyInspection,
        });
    };

    private readonly onDismiss = () => {
        this.setState({
            isDamSafetyInspectionEditDialogOpen: false,
        });
    }

    private readonly onUploadClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();
        if (!this.props.dam) return;
        this.setState({
            isDamSafetyInspectionEditDialogOpen: true,
        });
    };

    private readonly handleDamSafetyInspectionDelete = async () => {
        if (!this.props.session?.employee) return;

        if (this.state.damSafetyInspectionToDelete) {
            this.setState({
                isLoading: true,
            });

            const inactiveDamSafetyInspection = DamSafetyInspectionHelper.createInactiveSave(this.props.session, this.state.damSafetyInspectionToDelete);

            try {
                await DamSafetyInspectionHelper.save(inactiveDamSafetyInspection);
                GeneralFunctions.generalShowSuccessSnackbar('Dam Safety Inspection inactivated successfully.');
            } catch (e) {
                GeneralFunctions.generalShowErrorSnackbar('An error occurred while inactivated Dam Safety Inspection.');
            } finally {
                this.setState({
                    isLoading: false,
                });
            }
        }

        this.setState({
            damSafetyInspectionToDelete: null,
        });
    };

    public readonly render = () => {
        const { damSafetyInspections, isLoading } = this.state;
        const { dam, session } = this.props;

        return (
            <div className={'fdc flx1 pt20 pb20 pl20 pr20 bcg0 oya'}>
                <div className='fdc aife jcfe'>
                    <Tooltip title={'Upload'}>
                        <div>
                            <StadiumButton className='jcc aic p0 h42 br40' color='primary' onClick={this.onUploadClick} aria-label='Upload Video'>
                                <Icon className='cp ml10'>upload</Icon>
                                <Typography className='fw600 fs16 cp ml20 mr10'>DOCUMENT UPLOADS</Typography>
                            </StadiumButton>
                        </div>
                    </Tooltip>
                </div>
                {
                    isLoading &&
                    <div className='fdc flx1 aic jcc'>
                        <CircularProgress />
                    </div>
                }
                {
                    !isLoading &&
                    damSafetyInspections.length === 0 &&
                    <div className='fdc flx1 aic jcc'>
                        <Typography className={'mb10'} color='inherit' variant='h5'>
                            No Files uploaded
                        </Typography>
                    </div>
                }
                {
                    !isLoading &&
                    !!damSafetyInspections.length &&
                    <div className='fdc flx1 ais jcfs oya oxh mt12'>
                        {
                            damSafetyInspections.map(damSafetyInspection => (
                                <CustomAccordion 
                                    item={damSafetyInspection} 
                                    title={AppFunctionsService.formatDateTimeToDateOnly(damSafetyInspection.date)}
                                    onViewClick={!!damSafetyInspection?.documentURL ? this.onViewClick : undefined}
                                    onEditClick={session.employee.IsDamAdmin ? this.onEditClick : undefined}
                                    onDeleteClick={session.employee.IsDamAdmin ? this.onDamSafetyInspectionDelete : undefined}
                                    accordionDetails={
                                    <AccordionDetails className={'fdc flx1'}>
                                        <div className='fdr'>
                                            <div className='fdc w804'>
                                                <div className='fdc h1132 wfill'>
                                                    {
                                                        damSafetyInspection.documentURL &&
                                                        <section className='fdc flx1'>
                                                            <div className='fdc flx1'>
                                                                <embed height={'100%'} src={`${damSafetyInspection.documentURL}#toolbar=0&navpanes=0&scrollbar=0`} />
                                                            </div>
                                                        </section>
                                                    }
                                                </div>
                                            </div>
                                            <div className='fdc flx1 ml15'>
                                                <div className={'fdc flx1 mb15'}>
                                                    <div className='fdr jcfs'>
                                                        <AppBar position='static' className='dvblg' elevation={0}>
                                                            <Toolbar variant='dense'>
                                                                <Typography variant='h6' className='flx1 cw'>
                                                                    PDF DOCUMENT - DETAILS
                                                                </Typography>
                                                            </Toolbar>
                                                        </AppBar>
                                                    </div>
                                                    <div className='fdc jcfs bcw p15'>
                                                        <div className={'fdr aic jcc pb15 pl5 pr5'}>
                                                            <div className='fdc w50'>
                                                                <Typography className='fs13 cpd fwm'>No.</Typography>
                                                            </div>
                                                            <div className='fdc flx1'>
                                                                <Typography className='fs13 cpd fwm'>DESCRIPTION</Typography>
                                                            </div>
                                                            <div className='fdc w50'>
                                                                <Typography className='fs13 cpd fwm'>APP</Typography>
                                                            </div>
                                                            <div className='fdc w50'>
                                                                <Typography className='fs13 cpd fwm'>#</Typography>
                                                            </div>
                                                            <div className='fdc flx1'>
                                                                <Typography className='fs13 cpd fwm'>COMMENCE & COMPLETION DATES</Typography>
                                                            </div>
                                                            <div className='fdc w100'>
                                                                <Typography className='fs13 cpd fwm'>ESTIMATE COST</Typography>
                                                            </div>
                                                        </div>
                                                        {
                                                            damSafetyInspection.details.map((n, index) => (
                                                                <div key={n.no} className={`fdr aifs jcc mnh60 pt15 pl5 pr5 pb10 ${index % 2 === 0 ? 'bcg2' : ''}`}>
                                                                    <div className='fdc w50'>
                                                                        <Typography className='fs15 cpd fwb'>{n.no}</Typography>
                                                                    </div>
                                                                    <div className='fdc flx1'>
                                                                        <Typography className='fs15 cpd fwb'>{n.description}</Typography>
                                                                    </div>
                                                                    <div className='fdc w50'>
                                                                        <Typography className='fs15 cpd fwb'>{n.app ? 'YES' : 'NO'}</Typography>
                                                                    </div>
                                                                    <div className='fdc w50'>
                                                                        <Typography className='fs15 cpd fwb'>{n.priority}</Typography>
                                                                    </div>
                                                                    <div className='fdc flx1'>
                                                                        {
                                                                            n.commenceDate &&
                                                                            <div className='fdr'>
                                                                                <Typography className='fs15 cpd fwb'>C - {AppFunctionsService.formatDateTimeToDateOnly(n.commenceDate)}</Typography>
                                                                            </div>

                                                                        }
                                                                        {
                                                                            n.completionDate &&
                                                                            <div className='fdr'>
                                                                                <Typography className='fs15 cpd fwb'>Co - {AppFunctionsService.formatDateTimeToDateOnly(n.completionDate)}</Typography>
                                                                            </div>

                                                                        }
                                                                    </div>
                                                                    <div className='fdc w100'>
                                                                        <Typography className='fs15 cpd fwb'>{!n.estimateCost ? '-' : `R${n.estimateCost.toSAFormat(2)}`}</Typography>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                    <div className='fdr jcfs'>
                                                        <AppBar position='static' className='' elevation={0}>
                                                            <Toolbar variant='dense'>
                                                                <Typography variant='h6' className='flx1 cw'>
                                                                    COMMENTS
                                                                </Typography>
                                                                <span className={'flx1'} />
                                                            </Toolbar>
                                                        </AppBar>
                                                    </div>
                                                    <div className='fdc jcfs bcw p15'>
                                                        {
                                                            damSafetyInspection.comments.map((x, i) => (
                                                                <div key={i} className='fdc'>
                                                                    <div className='fdr aic bcg0 pl10 mb10'>
                                                                        <div className='fdc flx1'>
                                                                            <Typography className='fw700 cdg fs18 mt5 mb5'>
                                                                                {
                                                                                    x.createdByName
                                                                                }
                                                                            </Typography>
                                                                        </div>
                                                                        <div className='fdc jcc aic mr10'>
                                                                            <Typography className='cpd fs12'>
                                                                                {
                                                                                    AppFunctionsService.formatDateTime(x.createdOn)
                                                                                }
                                                                            </Typography>
                                                                        </div>
                                                                    </div>
                                                                    <div className='fdc ml10 mr10 mb10'>
                                                                        {
                                                                            /** This is ugly but needed for displaying new lines... */
                                                                            x.comment.split('\n').map(n => (
                                                                                <Typography key={n} className='fs15 cg3' component='p'>
                                                                                    {
                                                                                        n
                                                                                    }
                                                                                </Typography>
                                                                            ))
                                                                        }
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </AccordionDetails>
                                } />
                            ))
                        }
                    </div>
                }
                <ConfirmationDialog 
                    open={!!this.state.damSafetyInspectionToDelete}
                    isLoading={this.state.isLoading}
                    title={'Delete Dam safety inspection'}
                    description={'Are you sure you want to deactivate the selected inspection?'}
                    onAccept={this.handleDamSafetyInspectionDelete}
                    onClose={() => this.setState({ damSafetyInspectionToDelete: null })}
                    dialogType='red'
                />
                <EditDamSafetyInspection
                    transition={Transitions.Down}
                    dam={dam}
                    damSafetyInspection={this.state.currentDamSafetyInspection}
                    isOpen={this.state.isDamSafetyInspectionEditDialogOpen}
                    onDismiss={this.onDismiss}
                />
            </div>
        );
    };
}
