import Paper from '@material-ui/core/Paper';
import { Formik } from 'formik';
import React from 'react';
import '../../../../style/transformer/LPUCapturing.scss';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import LPUCapturingFormTableRow from '../form/LPUTableFormRow';
import { ITransformerInvoice } from '../../../../@types/model/transformer/transformerInvoice';
import generalFunctions from '../../../../store/general/functions';
import OutlinedStadiumButton from '../../../customComponents/button/OutlinedStadiumButton';
import StadiumButton from '../../../customComponents/button/StadiumButton';
import navFunctions from '../../../../store/nav/functions';
import moment from 'moment';
import { DATE_INPUT_FORMAT_DEFAULT } from '../../../../appConstants';
import { createSelector } from 'reselect';
import { ILPULine } from '../../../../@types/model/transformer/tariffs';
import FormCheckbox from '../../../customComponentV2/FormCheckbox';

interface ILPUCapturingTableProps {
    multi ?: boolean;
    invoices : Array<ITransformerInvoice<ILPULine>>;
    disabledTransformers : Array<string>;

    selectedEpNumber ?: string;

    onSubmit ?: (invoices : Array<ITransformerInvoice<ILPULine>>) => void;
    onCancelClick ?: () => void;
}

interface ILPUCapturingTableState {
}

export default class LPUCapturingTable extends React.PureComponent<ILPUCapturingTableProps, ILPUCapturingTableState> {
    constructor(props : ILPUCapturingTableProps) {
        super(props);
        this.state = {
        };
    }

    public readonly onSubmit = async (values : Array<ITransformerInvoice<ILPULine>>) => {

        if (values.some(invoice => invoice.totalBilled && !moment.utc(invoice.paymentDueDate ?? '', DATE_INPUT_FORMAT_DEFAULT, true).isValid())) {

            generalFunctions.generalShowErrorSnackbar('Invalid due date');
            return;
        }
        
        if (this.props.onSubmit) this.props.onSubmit(values);
    };

    public readonly onCancelClick = () => {
        if (this.props.onCancelClick) {
            this.props.onCancelClick();
            return;
        }
        navFunctions.navReplacePath('/transformer/capturing', 'type=lpu');
    };

    public readonly getInvoices = (state : ILPUCapturingTableState, props : ILPUCapturingTableProps) => props.invoices;
    public readonly getSortedInvoices = createSelector(
        [this.getInvoices],
        (invoices) => {
            return invoices
                .sort((a, b) => Number(a.accountNumber) - Number(b.accountNumber));
        },
    );

    public readonly render = () => {
        const { multi, selectedEpNumber, disabledTransformers } = this.props;
        const invoices = this.getSortedInvoices(this.state, this.props);
        return (
            <div className='fdc flx1 bct'>
                <Formik
                    enableReinitialize
                    initialValues={invoices}
                    onSubmit={this.onSubmit}
                >
                    {
                        ({
                            values,
                            isSubmitting,
                            handleChange,
                            handleBlur,
                            handleReset,
                            setFieldValue,
                            dirty,
                        }) => (
                            <form id='lpu-form' className='fdc flx1' onSubmit={() => this.onSubmit(values)} onReset={handleReset}>
                                <Paper className='bct oyo'>
                                    {
                                        this.props.invoices.length === 1 &&
                                        <FormCheckbox
                                            name={`0.isActive`}
                                            label={'Is Active?'}
                                            disabled={this.props.invoices[0].isActive}
                                        />
                                    }
                                    <Table className='lpu-capture-table'>
                                        <TableHead>
                                            <TableRow className='header-row'>
                                                <TableCell className='waypoint w42 fixed'>
                                                    Way Point
                                                </TableCell>
                                                <TableCell className='account w77'>
                                                    Account No
                                                </TableCell>
                                                <TableCell className='w45'>
                                                    Utilized Capacity NAC
                                                </TableCell>
                                                <TableCell className='w45'>
                                                    Demand Reading
                                                </TableCell>
                                                <TableCell className='w45'>
                                                    Reactive Energy All
                                                </TableCell>
                                                <TableCell className='w45'>
                                                    Days
                                                </TableCell>
                                                <TableCell className='w45'>
                                                    Admin Charge
                                                </TableCell>
                                                <TableCell className='w45'>
                                                    Network Demand Units
                                                </TableCell>
                                                <TableCell className='w45'>
                                                    Ancillary Service Charge
                                                </TableCell>
                                                <TableCell className='w45'>
                                                    Units STD Low
                                                </TableCell>
                                                <TableCell className='w45'>
                                                    Units off-peak Low
                                                </TableCell>
                                                <TableCell className='w45'>
                                                    Units peak Low
                                                </TableCell>
                                                <TableCell className='w45'>
                                                    Units STD High
                                                </TableCell>
                                                <TableCell className='w45'>
                                                    Units Peak High
                                                </TableCell>
                                                <TableCell className='w45'>
                                                    Units off-Peak High
                                                </TableCell>
                                                <TableCell className='w45'>
                                                    Service Charge
                                                </TableCell>
                                                <TableCell className='w45'>
                                                    Excess Network Access Charge
                                                </TableCell>
                                                <TableCell className='w45'>
                                                    Excess Reactive Energy (kVArh)
                                                </TableCell>
                                                <TableCell className='w45'>
                                                    Standard Connection Charge
                                                </TableCell>
                                                <TableCell className='w45'>
                                                    Interest on Overdue Account
                                                </TableCell>
                                                <TableCell className='w77 zi1'>
                                                    Due Date
                                                </TableCell>
                                                <TableCell className='invoice-total w65'>
                                                    Invoice Total
                                                </TableCell>
                                                <TableCell className='invoice-total w65'>
                                                    Billed Total
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {
                                            values
                                                .map((invoice, index) => (
                                                    <TableBody id={invoice.accountNumber}
                                                        className={`${selectedEpNumber === invoice.epNumber ? 'selected' : ''} ${invoice.lines.length > 1 ? 'multi' : ''} invoice`}
                                                        key={index}
                                                    >
                                                        {
                                                            invoice.lines.map((line, lineIndex) => (
                                                                <LPUCapturingFormTableRow
                                                                    key={`value_${invoice.epNumber}_${lineIndex}`}
                                                                    disabled={disabledTransformers.includes(invoice.epNumber)}
                                                                    invoice={invoice}
                                                                    index={index}
                                                                    lineIndex={lineIndex}
                                                                    line={line}
                                                                    adminCharge={invoice.adminCharge}
                                                                    serviceCharge={invoice.serviceCharge}
                                                                    standardConnectionCharge={invoice.standardConnectionCharge}
                                                                    isSubmitting={isSubmitting}
                                                                    handleChange={handleChange}
                                                                    handleBlur={handleBlur}
                                                                    setFieldValue={setFieldValue}
                                                                    multi={multi}
                                                                />
                                                            ))
                                                        }
                                                        
                                                    </TableBody>
                                                ))
                                        }
                                    </Table>
                                </Paper>
                                <span className='flx1' />
                                <div className='fdr jcfe aife mt15'>
                                    <OutlinedStadiumButton className='mr16' variant='text' type='button' onClick={this.onCancelClick} disabled={isSubmitting}>
                                            CANCEL
                                    </OutlinedStadiumButton>
                                    <StadiumButton type='submit' disabled={isSubmitting || !dirty}>
                                            SAVE
                                    </StadiumButton>
                                </div>
                            </form>
                        )
                    }
                </Formik>
            </div>
        );
    };
}
