import React from 'react';
import ProjectDialog from './ProjectDialog';
import { DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import PillButton from '../button/PillButton';

export interface IConfirmationDialogProps {
    open : boolean;
    title : string;
    description : string;
    onAccept : () => void;
    onClose : () => void;
    isLoading ?: boolean;
    dialogType ?: 'green' | 'red' | 'orange';
}

/**
 * @param {boolean} open - Determines if the confirmationDialog is open or closed. Required.
 * @param {string} title - Text to be displayed in the dialog header. Required.
 * @param {string} description - Text to be displayed in the dialog content section. Required.
 * @param {() => void} onClose - Callback function to be invoked when the dialog is closed. Required.
 * @param {() => void} onAccept - Callback function to be invoked when the dialog's accept button is clicked. Required.
 * @param {boolean} isLoading - Circular loading indicator inside the dialog when true. Optional.
 * @param {'green' | 'red' | 'orange'} dialogType - Set the dialog header colour. Optional. Defaults to 'green'.
 * 
 * @return {React.ReactElement} The React element for the Confirmation Dialog component.
 */
const ConfirmationDialog = (props : IConfirmationDialogProps) : React.ReactElement => {
    return (
        <ProjectDialog
            isOpen={props.open}
            onClose={props.onClose}
            title={props.title.toUpperCase()}
            isLoadingCircular={props.isLoading}
            headerColour={props.dialogType}
        >
            <DialogContent>
                <DialogContentText>{props.description}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onAccept} color='primary'>
                    Okay
                </Button>
                <PillButton
                    className={'ml15 pl20 pr20 h35 cp bcy'}
                    text={'Cancel'}
                    onClick={props.onClose}/>
            </DialogActions>        
        </ProjectDialog>
    );
};

export default ConfirmationDialog;
