import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import Toolbar from '@material-ui/core/Toolbar';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import React from 'react';
import TariffHelper, { ILPULine, ILPUTariff } from '../../../@types/model/transformer/tariffs';
import TransformerHelper, { ITransformer } from '../../../@types/model/transformer/transformer';
import { ITransformerGroup } from '../../../@types/model/transformer/transformerGroup';
import { ITransformerInvoice, TransformerInvoiceHelper } from '../../../@types/model/transformer/transformerInvoice';
import { FirebaseService } from '../../../services/firebaseService';
import generalFunctions from '../../../store/general/functions';
import DropdownButton from '../../customComponents/button/DropdownButton';
import TransformerGroupDropdown from '../../customComponents/selector/transformer/GroupSelector';
import TariffConfirmTable from './table/ConfirmTable';
import LPUCapturingTable from './table/LPUCaptureTable';
import lodash from 'lodash';
import { IAuthState, IRootState } from '../../../@types/redux';
import { connect } from 'react-redux';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { firestore } from 'firebase/app';

interface ITransformerLPUCaptureProps {
    auth : IAuthState;
    onDismiss : () => void;
}

interface ITransformerLPUCaptureState {
    monthDate : moment.Moment | null;
    isLoading : boolean;
    group ?: string;
    selectedEpNumber ?: string;

    showCapture : boolean;

    lpuTariffs : Array<ILPUTariff>;
    transformers : Array<ITransformer>;

    invoices : Array<ITransformerInvoice<ILPULine>>;
    currentInvoices : Array<ITransformerInvoice<ILPULine>>;

    tariffChange : boolean;
}

class TransformerLPUCapture extends React.PureComponent<ITransformerLPUCaptureProps, ITransformerLPUCaptureState> {
    private readonly maxDate = moment.utc().startOf('month');
    constructor(props : ITransformerLPUCaptureProps) {
        super(props);
        this.state = {
            isLoading: false,
            monthDate: null,
            lpuTariffs: [],
            transformers: [],
            showCapture: true,
            invoices: [],
            currentInvoices: [],
            tariffChange: false,
        };
    }

    private readonly loadData = async () => {
        const { monthDate, group, lpuTariffs, transformers } = this.state;

        if (!monthDate) return;
        if (!group) return;
        if (!lpuTariffs.length) return;
    
        this.setState({
            isLoading: true,
        });

        const currentInvoices = (await TransformerInvoiceHelper.collection<ILPULine>()
            .where('type', '==', 'lpu')
            .where('monthDate', '==', firestore.Timestamp.fromMillis(monthDate.valueOf()))
            .get())
            .docs
            .map(x => x.data())
            .filter(x => x.isActive);

        const invoices : Array<ITransformerInvoice<ILPULine>> = [];

        try {
            const startOfMonth = moment.utc(monthDate.valueOf());
            const endOfMonth = moment.utc(monthDate.valueOf()).endOf('month').startOf('day');

            transformers
                .forEach((transformer) => {
                    const lines : Array<ILPULine> = [];

                    const currentInvoice = currentInvoices.find(x => x.epNumber === transformer.EPNumber);

                    if (currentInvoice) {
                        invoices.push({
                            ...currentInvoice,
                        });
                        return;
                    }
    
                    if (!transformer.TransmissionZone) return;
                    if (!transformer.LineVoltage) return;
                    if (lpuTariffs.length > 1) {
                        lpuTariffs.forEach((tariff, index) => {
                            if (!transformer.TransmissionZone) return;
                            if (!transformer.LineVoltage) return;

                            const tariffStartDate = moment.utc(tariff.startDate).startOf('day');
                            const tariffEndDate = !tariff.endDate ? null : moment.utc(tariff.endDate).startOf('day');

                            const startDate = tariffStartDate.isAfter(startOfMonth) ? tariffStartDate : startOfMonth;
                            const endDate = tariffEndDate?.isBefore(endOfMonth) ? tariffEndDate : endOfMonth;

                            const tariffValues = TariffHelper.getLPUTariffValues(tariff, transformer, endDate);

                            const line = {
                                tariffValues,
                                tariff,
                                transmissionZone: transformer.TransmissionZone,
                                lineVoltage: transformer.LineVoltage,
                                days: endDate.diff(startDate, 'days') + index,
                                networkDemandUnits: 0,
                                ancillaryServiceCharge: 0,
                                unitsSTDLow: 0,
                                unitsOffPeakLow: 0,
                                unitsSTDHigh: 0,
                                unitsOffPeakHigh: 0,
                                unitsPeakHigh: 0,
                                unitsPeakLow: 0,
                                serviceCharge: 0,
                                excessNetworkAccessCharge: 0,
                                standardConnectionCharge: 0,
                                excessReactiveEnergy: 0,
                                interestCharge: 0,
                            
                                total: 0,
                            };

                            lines.push({
                                ...line,
                            });
                        });
                    } else {
                        const rating = (transformer.TransformerRating ?? 0);
                        const category = lpuTariffs[0].categories.find(x => x.lower < rating && rating < x.upper);

                        const tariffValues = TariffHelper.getLPUTariffValues(lpuTariffs[0], transformer, endOfMonth);
                        const line = {
                            tariffValues,
                            days: monthDate.daysInMonth(),
                            tariff: lpuTariffs[0],
                            adminCharge: (category?.administrationCharge ?? 0) * monthDate.daysInMonth(),
                            networkDemandUnits: 0,
                            ancillaryServiceCharge: 0,
                            unitsSTDLow: 0,
                            unitsOffPeakLow: 0,
                            unitsSTDHigh: 0,
                            unitsOffPeakHigh: 0,
                            serviceCharge: 0,
                            unitsPeakHigh: 0,
                            unitsPeakLow: 0,
                            excessNetworkAccessCharge: 0,
                            standardConnectionCharge: 0,
                            excessReactiveEnergy: 0,
                            interestCharge: 0,

                            total: 0,
                        };

                        lines.push({
                            ...line,
                        });
                    }

                    invoices.push(this.invoiceFromLines(transformer, lines));
                });
        } catch (ex) {
            generalFunctions.generalShowError(ex, `${ex}`);
        } finally {
            this.setState({
                isLoading: false,
                invoices,
                selectedEpNumber: undefined,
                currentInvoices,
            });

        }
    };
    
    private readonly invoiceFromLines : (transformer : ITransformer, lines : Array<ILPULine>) => ITransformerInvoice<ILPULine> = (transformer : ITransformer, lines : Array<ILPULine>) => {
        const vat = FirebaseService.getVat();
        const { auth } = this.props;

        if (!auth.session) throw new Error('Auth Required');

        return TransformerInvoiceHelper.calculateLPUTotal({
            transformer,
            accountNumber: transformer.AccountNumber ?? '',
            days: lodash.sumBy(lines, line => line.days),
            epNumber: transformer.EPNumber,
            epNumberRef: TransformerHelper.doc(transformer.EPNumber).path,
            poleNumber: transformer.PoleNumber ?? '',
            lines: [ ...lines ],
            totalUsage: 0,
            totalInterest: 0, 
            reactiveEnergy: 0,
            utilizedCapacity: 0,
            vatPercentage: vat,
            totalCharge: 0,
            vatCharge: 0,
            totalDue: 0,
            totalBilled: 0,
            type: 'lpu',
            paymentDueDate: null,
            demandReading: 0,
            adminCharge: 0,
            serviceCharge: 0,
            standardConnectionCharge: 0,
            createdBy: auth.session.firebaseUser.uid,
            createdByEmployee: auth.session.employee.EmployeeNumber ?? '',
            createdByName: auth.session.employee.Name,
            createdOn: moment.utc().valueOf(),
            updatedBy: auth.session.firebaseUser.uid,
            updatedByEmployee: auth.session.employee.EmployeeNumber ?? '',
            updatedByName: auth.session.employee.Name,
            updatedOn: moment.utc().valueOf(),
            paid: false,
            paidBy: null,
            paidByName: null,
            paidByEmployeeNumber: null,
            paidOn: null,
            journaled: false,
            journal: null,
            journalNr: null,
            journaledBy: null,
            journaledByName: null,
            journaledByEmployeeNumber: null,
            journaledOn: null,
            id: '',
            group: this.state.group ?? '',
            monthDate: this.state.monthDate?.valueOf() ?? 0,
            related: null,
            relatedId: null,
            reverted: false,
            credit: 0,
            isActive: true,
        });
    };

    private readonly loadTariffs = async (monthDate : moment.Moment, tariffChange : boolean) => {
        this.setState({
            isLoading: true,
        });

        try {
            const tariffs = (await TariffHelper.lpuCollection().get())
                .docs
                .map(n => ({
                    ...n.data(),
                }))
                .sort((a, b) => a.startDate - b.startDate);

            const lpuTariffs = tariffs
                .filter(x => monthDate.isBetween(
                    moment.utc(x.startDate),
                    x.endDate ? moment.utc(x.endDate).subtract(1, 'day') : moment.utc(monthDate).endOf('month'),
                    'month', '[]'))
                .sort((a, b) => a.startDate - b.startDate);

            // Manual force for tariff change, we get the previous tariff
            if (tariffChange && lpuTariffs.length === 1) {
                const tariff = lpuTariffs[0];
                const index = tariffs.findIndex(x => x.ref.id === tariff.ref.id);

                if (index > 0) {
                    lpuTariffs.unshift(tariffs[index - 1]);
                }
            }

            this.setState({
                lpuTariffs,
            });
        } catch (ex) {
            generalFunctions.generalShowErrorSnackbar(`${ex}`);
        } finally {
            this.setState({
                isLoading: false,
            });
        }
    };

    private readonly loadTransformers = async (group : string) => {
        this.setState({
            isLoading: true,
        });

        try {
            const transformers = await TransformerHelper.listen().where('EPGroup', '==', group).get();
            this.setState({
                transformers: transformers.docs.map(n => n.data()).filter(x => !!x.IsActive),
            });
        } catch (ex) {
            generalFunctions.generalShowError(ex, 'Error loading transformers.');
        } finally {
            this.setState({
                isLoading: false,
            });
        }
    };

    private readonly onMonthDateChanged = (momentDate : moment.Moment | null) => {
        const monthDate = !momentDate ? null : moment.utc(momentDate.valueOf()).startOf('month');
        this.setState({
            lpuTariffs: [],
            invoices: [],
            monthDate,
        });

        if (!monthDate) return;

        this.loadTariffs(monthDate, this.state.tariffChange).then(this.loadData);
    };

    private readonly onEPGroupChange = (value ?: ITransformerGroup) => {
        const group = value?.name;
        this.setState({
            transformers: [],
            invoices: [],
            group,
        });

        if (!group) return;

        this.loadTransformers(group).then(this.loadData);
    };
    
    private readonly onCaptureSubmit = (invoices : Array<ITransformerInvoice<ILPULine>>) => {
        this.setState({
            invoices: invoices,
            showCapture: false,
            selectedEpNumber: undefined,
        });
    };

    private readonly onConfirmCancel = () => {
        this.setState({
            showCapture: true,
        });
    };

    private readonly onConfirmClick = () => {
        this.save();
    };

    private readonly save = async () => {
        const { auth } = this.props;

        if (!auth.session) return;

        this.setState({
            isLoading: true,
        });

        try {
            const currentInvoicesNumbers = this.state.currentInvoices.map(x => x.epNumber);
            const invoices = this.state.invoices
                .filter(x => !currentInvoicesNumbers.includes(x.epNumber))
                .filter(x => x.totalBilled) // We only want to create invoices for EPs that are billed.
                .map(invoice => ({
                    ...invoice,
                    lines: [ ...invoice.lines ],
                }));

            await TransformerInvoiceHelper.saveMany(invoices);
            generalFunctions.generalShowSuccessSnackbar('Success');
            this.props.onDismiss();
        } catch (ex) {
            generalFunctions.generalShowError(ex, `${ex}`);
            this.setState({
                isLoading: false,
            });
        }
    };

    private readonly onConfirmRowClick = (selectedEpNumber : string) => {
        this.setState({
            showCapture: true,
            selectedEpNumber,
        });
    };

    private readonly onTariffChangeChange = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        this.setState({
            tariffChange: checked,
        });
        if (!this.state.monthDate) return;

        this.loadTariffs(this.state.monthDate, checked).then(this.loadData);
    };

    public readonly render = () => {
        const { isLoading, monthDate, group, lpuTariffs,
            showCapture, invoices, selectedEpNumber, tariffChange, currentInvoices } = this.state;
        return (
            <div className='fdc flx1 p10 mh0 mw0 bcg0'>
                {
                    showCapture &&
                    <Toolbar>
                        <span className={'flx1'} />
                        <div className='fdr flx1 aifs jcfs'>
                            <div className={'flx1 aifs p5 mb10 pr20'}>
                                <FormControl fullWidth>
                                    <DatePicker value={monthDate}
                                        disabled={isLoading}
                                        views={['month']}
                                        maxDate={this.maxDate}
                                        onChange={this.onMonthDateChanged} format='MMM YYYY' label='Select Account Month...'
                                        id='monthDate'
                                        className={'TextField'}
                                        margin='normal'
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    <DropdownButton />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormControl>
                            </div>
                            <div className={'flx1 aifs p5 mb10 pr20 mt16'}>
                                <TransformerGroupDropdown
                                    fullWidth
                                    value={group}
                                    onChange={this.onEPGroupChange}
                                    type={'LPU'}
                                    disabled={isLoading}
                                    required
                                />
                            </div>
                            <div className={'fdc flx1 aifs p5 mb10 pr20 mt26'}>
                                <div className='fdr aic'>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                value={tariffChange}
                                                onChange={this.onTariffChangeChange}
                                                color='primary'
                                                checked={tariffChange}
                                            />
                                        }
                                        labelPlacement='start'
                                        label='Tariff change?'
                                    />
                                </div>
                            </div>
                        </div>
                    </Toolbar>
                }
                {
                    isLoading &&
                    <div className='fdc flx1 aic jcc mh0 mw0'>
                        <CircularProgress />
                    </div>
                }
                {
                    !isLoading &&
                    showCapture &&
                    monthDate &&
                    group &&
                    <div className='fdc hfill mh0 mw0'>
                        <LPUCapturingTable
                            invoices={invoices}
                            disabledTransformers={currentInvoices.map(x => x.epNumber)}
                            multi={lpuTariffs.length > 1}
                            onSubmit={this.onCaptureSubmit}
                            selectedEpNumber={selectedEpNumber}
                        />
                    </div>
                }
                {
                    !isLoading &&
                    !showCapture &&
                    !!invoices.length &&
                    group &&
                    <div className='fdc flx1 mh0 mw0'>
                        <TariffConfirmTable
                            invoices={invoices}
                            group={group}
                            onCancelClick={this.onConfirmCancel}
                            onConfirmClick={this.onConfirmClick}
                            onRowClick={this.onConfirmRowClick}
                            disabledTransformers={currentInvoices.map(x => x.epNumber)}
                        />
                    </div>
                }
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        auth: state.auth,
    };
};

export default connect(
    mapStateToProps,
)(TransformerLPUCapture);
