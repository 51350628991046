import firebase from 'firebase';
import firebaseApp from '../../../services/firebaseService';
import { IOptionType } from '../optionType';
import { IDivision } from './division';

export default class DivisionModelHelper {
    public static readonly COLLECTION_NAME = 'division';

    /*================================================================================================================
     *                                                  Converters
     * ==============================================================================================================*/

    private static converter : firebase.firestore.FirestoreDataConverter<IDivision> = {

        fromFirestore: (snapshot) => {
            return DivisionModelHelper.fromFirestoreDocument(snapshot);
        },

        toFirestore: (data : IDivision) : firebase.firestore.DocumentData => {
            return DivisionModelHelper.toFirestoreDocument(data);
        },
    };

    public static fromFirestoreDocument(snapshot : firebase.firestore.DocumentSnapshot | firebase.firestore.QueryDocumentSnapshot) : IDivision {
        const data = snapshot.data() as IDivision;
        if (!snapshot.exists) throw new Error(`Document does not exist! ${snapshot.id}`);
        
        return {
            ...data,
            ref: snapshot.ref as firebase.firestore.DocumentReference<IDivision>,
        };
    }

    public static toFirestoreDocument(data : IDivision) {
        return DivisionModelHelper.convertToObject(data);
    }

    public static convertToObject(model : IDivision) {
        const obj = {
            ...model,
        };

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { ref, ...rest } = obj;
        return rest;
    }

    public static convertToOptionType = (divisions: Array<IDivision>) : Array<IOptionType> => {
        return divisions.map(x => {
            return {value: x.ref.id, label: x.ref.id};
        });  ;
    };

    /*================================================================================================================
     *                                                Firestore Methods
     * ==============================================================================================================*/

    public static collection() {
        return firebaseApp
            .firestore()
            .collection(DivisionModelHelper.COLLECTION_NAME)
            .withConverter(DivisionModelHelper.converter);
    }

    /*================================================================================================================
     *                                                  Form Values
     * ==============================================================================================================*/

    /*================================================================================================================
     *                                                  Schemas
     * ==============================================================================================================*/

}
