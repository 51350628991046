import { Fab } from '@material-ui/core';
import React from 'react';

export interface IPillButtonProps {
    text : string;
    onClick ?: (event : React.MouseEvent<HTMLElement, MouseEvent>) => void;

    id ?: string;
    value ?: string | number | Array<string>;
    size ?: 'small' | 'medium' | 'large';
    color ?: 'inherit' | 'primary' | 'secondary' | 'default';
    className ?: string;
    disabled ?: boolean;
    textClassName ?: string;
    disableUppercase ?: boolean;
    icon ?: React.ReactNode;
    iconPosition ?: 'start' | 'end';
    type ?: 'submit' | 'reset';
}

/**
 * @param {string} text - The text to display on the button. Required.
 * @param {(event: React.MouseEvent<HTMLElement, MouseEvent>) => void} onClick - Optional callback function that is called when the button is clicked. Receives the click event as an argument.
 * @param {string} id - Optional identifier for the button element, used for targeting in tests or JavaScript.
 * @param {string | number | Array<string>} value - Optional value associated with the button, which can be a string, number, or array of strings.
 * @param {'small' | 'medium' | 'large'} size - Optional size of the button; can be 'small', 'medium', or 'large'.
 * @param {'inherit' | 'primary' | 'secondary' | 'default'} color - Optional preset color theme for the button; choices include 'inherit', 'primary', 'secondary', and 'default'.
 * @param {string} className - Optional custom CSS class name to apply additional styling to the button.
 * @param {boolean} disabled - Optional flag that disables the button, preventing interactions.
 * @param {string} textClassName - Optional custom CSS class name to apply additional styling to the text inside the button.
 * @param {boolean} disableUppercase - Optional flag to prevent transforming the button text to uppercase.
 * @param {React.ReactNode} icon - Optional icon to display alongside the button text. This can be any React node.
 * @param {'start' | 'end'} iconPosition - Optional position of the icon relative to the text; can be 'start' (before the text) or 'end' (after the text).
 * @param {'submit' | 'reset'} type - Optional type attribute for the button element, typically used when the button is part of a form. Can be 'submit' or 'reset'.
 */

const PillButton = (props : IPillButtonProps) : React.ReactElement => {
    const getFontSizeClass = () : string => {

        let value = 'fs20'; //default

        if (props.size === 'small') {
            value = 'fs13';
        }
        if (props.size === 'medium') {
            value = 'fs14';
        }
        if (props.size === 'large') {
            value = 'fs15';
        }
        return value;
    };

    return (
        <Fab
            id={props.id}
            value={props.value}
            variant='extended'
            color={props.color}
            disabled={props.disabled}
            size={props.size}
            type={props.type}
            onClick={props.onClick}
            className={`fdr aic  
                ${props.size === 'small' ? 'pl5 pr5' : ''} 
                ${props.color === 'secondary' ? 'cpd' : ''} 
                ${props.className}`}>        
            {
                (props.icon || (props.icon && props.iconPosition === 'start')) &&
                <span className={'flx1 aic jcfs'}>
                    {props.icon}
                </span>
            }
            <span className={`${props.icon ? 'flx3 jcfs ttn' : ''} ${getFontSizeClass} ${props.textClassName}`}>
                {
                    props.disableUppercase ? props.text : props.text.toUpperCase()
                }
            </span>
            {
                props.icon && props.iconPosition === 'end' &&
                <span className={'flx1 aic jcfs'}>
                    {props.icon}
                </span>
            }
        </Fab>
    );
};

export default PillButton;