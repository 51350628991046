import firebase from 'firebase';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';
import firebaseApp from '../../../services/firebaseService';
import { IUserSession } from '../../employee';
import { IRiver, IRiverVNotch } from './river';
import { IOptionType } from '../optionType';
import { IRiverFormValues } from './riverFormValues';

export default class RiverModelHelper {
    public static readonly COLLECTION_NAME = 'river';

    /*================================================================================================================
     *                                                  Converters
     * ==============================================================================================================*/

    private static converter : firebase.firestore.FirestoreDataConverter<IRiver> = {

        fromFirestore: (snapshot) => {
            return RiverModelHelper.fromFirestoreDocument(snapshot);
        },

        toFirestore: (data : IRiver) : firebase.firestore.DocumentData => {
            return RiverModelHelper.toFirestoreDocument(data);
        },
    };

    public static fromFirestoreDocument(snapshot : firebase.firestore.DocumentSnapshot | firebase.firestore.QueryDocumentSnapshot) : IRiver {
        const data = snapshot.data() as IRiver;
        if (!snapshot.exists) throw new Error(`Document does not exist! ${snapshot.id}`);
        
        return {
            ...data,
            ref: snapshot.ref as firebase.firestore.DocumentReference<IRiver>,
        };
    }

    public static toFirestoreDocument(data : IRiver) {
        return RiverModelHelper.convertToObject(data);
    }

    public static convertToObject(model : IRiver) {
        const obj = {
            ...model,
        };

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { ref, ...rest } = obj;
        return rest;
    }

    /*================================================================================================================
     *                                                Firestore Methods
     * ==============================================================================================================*/

    public static collection() {
        return firebaseApp
            .firestore()
            .collection(RiverModelHelper.COLLECTION_NAME)
            .withConverter(RiverModelHelper.converter);
    }

    public static async save(entry : IRiver) : Promise<void> {
        RiverModelHelper
            .collection()
            .doc(entry.ref.id)
            .set(entry, { merge: true });

    }

    /*================================================================================================================
     *                                                  Form Values
     * ==============================================================================================================*/

    public static createFormValues = (user : IUserSession, existing ?: IRiver | null) : IRiverFormValues => {
        if (existing) {
            const divisionOption : IOptionType = {label: existing.Division ?? '', value: existing.Division ?? ''}
             return {
                GUID: existing.GUID ?? null,
                Division: divisionOption ?? null,
                DivisionDescriptionAccpac: existing.DivisionDescriptionAccpac ?? null,
                DivisionNumber: existing.DivisionNumber ?? null,
                ManagementArea: existing.ManagementArea ?? null,
                RiverName: existing.RiverName ?? null,
                WRNumber: existing.WRNumber ?? null,
                RiverImageName: existing.RiverImageName ?? null,
                RiverImageThumbnailUrl: existing.RiverImageThumbnailUrl ?? null,
                RiverImageUrl: existing.RiverImageUrl ?? null,
                LatestFlowType: existing.LatestFlowType ?? null,
                LatestFlow: existing.LatestFlow ?? null,
                LatestFlowMeasure: existing.LatestFlowMeasure ?? null,
                FlowMonitoringStatus: existing.FlowMonitoringStatus ?? false,
                Geom: existing.Geom ?? null,
                Elevation: Number.isNaN(existing.Elevation) ? 0 : existing.Elevation,
                Vnotch: existing.Vnotch ?? null,
                IsActive: existing.IsActive ?? null,
                IsSent: existing.IsSent ?? null,
            };
        } else {
            return {
                GUID: uuidv4(),
                Division: null,
                DivisionDescriptionAccpac: null,
                DivisionNumber: null,
                ManagementArea: null,
                RiverName: '',
                WRNumber: null,
                RiverImageName: null,
                RiverImageThumbnailUrl: null,
                RiverImageUrl: null,
                LatestFlowType: null,
                LatestFlow: null,
                LatestFlowMeasure: null,
                FlowMonitoringStatus: false,
                Geom: null,
                Elevation: 0,
                Vnotch: [],
                IsActive: false,
                IsSent: false,
            };
        }
    };

    public static createInactiveSave = (user : IUserSession, entry : IRiver) : IRiver => {        
        return {
            ...entry,
            UpdatedByEmployee: user.employee.EmployeeNumber ?? '',
            UpdatedByName: user.employee.Name,
            UpdatedBy: firebaseApp
                .firestore()
                .collection('employee')
                .doc(user.firebaseUser.uid),

            UpdatedOn: firebase.firestore.Timestamp.now(),
            IsActive: false,
        };
    };

    public static createSave = (user : IUserSession, formValues : IRiverFormValues, existingEntry ?: IRiver | null) : IRiver => {       
        if (existingEntry) {
            return {
                ...existingEntry,
                ...formValues,
                WRNumber: formValues.WRNumber ?? existingEntry.WRNumber,
                Division: formValues.Division?.label ?? existingEntry.Division,
                FlowMonitoringStatus: formValues.FlowMonitoringStatus ?? existingEntry.FlowMonitoringStatus,
                RiverName: formValues.RiverName ?? existingEntry.RiverName,
                UpdatedByEmployee: user.employee.EmployeeNumber ?? '',
                UpdatedByName: user.employee.Name,
                UpdatedBy: firebaseApp.firestore().collection('employee').doc(user.firebaseUser.uid),
                UpdatedOn: firebase.firestore.Timestamp.now(),
            };

        } else {
            return {
                GUID: formValues.GUID,
                Division: formValues.Division?.label ?? null,
                DivisionDescriptionAccpac: formValues.DivisionDescriptionAccpac,
                DivisionNumber: formValues.DivisionNumber,
                ManagementArea: formValues.ManagementArea,
                RiverName: formValues.RiverName,
                WRNumber: formValues.WRNumber ?? '',
                RiverImageName: formValues.RiverImageName,
                RiverImageThumbnailUrl: formValues.RiverImageThumbnailUrl,
                RiverImageUrl: formValues.RiverImageUrl,
                LatestFlowType: formValues.LatestFlowType,
                LatestFlow: formValues.LatestFlow,
                LatestFlowMeasure: formValues.LatestFlowMeasure,
                FlowMonitoringStatus: formValues.FlowMonitoringStatus,
                Geom: formValues.Geom,
                Elevation: formValues.Elevation,
                Vnotch: formValues.Vnotch,
                IsActive: formValues.IsActive,
                IsSent: formValues.IsSent,
                CreatedByEmployee: user.employee.EmployeeNumber ?? '',
                CreatedByName: user.employee.Name,
                CreatedOn: firebase.firestore.Timestamp.now(),
                CreatedBy: firebaseApp.firestore().collection('employee').doc(user.firebaseUser.uid),
                UpdatedByEmployee: user.employee.EmployeeNumber ?? '',
                UpdatedByName: user.employee.Name,
                UpdatedBy: firebaseApp.firestore().collection('employee').doc(user.firebaseUser.uid),
                UpdatedOn: firebase.firestore.Timestamp.now(),
                ref: this.collection().doc(formValues.WRNumber ?? ''),
            };
        }
    };

    /*================================================================================================================
     *                                                  Schemas
     * ==============================================================================================================*/

    public static formSchema = () : Yup.ObjectSchema<IRiverFormValues> => Yup.object({
        GUID: Yup.string().required('Required'), 
        Division: Yup.mixed<IOptionType>().nullable().defined(),
        DivisionDescriptionAccpac: Yup.string().nullable().defined(),
        DivisionNumber: Yup.number().nullable().defined(),
        ManagementArea: Yup.string().nullable().defined(),
        RiverName: Yup.string().required('Required'),
        WRNumber: Yup.string().trim().min(1, 'Must have at least 1 character').required('Required'),
        RiverImageName: Yup.string().nullable().defined(),
        RiverImageThumbnailUrl: Yup.string().nullable().defined(),
        RiverImageUrl: Yup.string().nullable().defined(),
        LatestFlowType: Yup.string().nullable().defined(),
        LatestFlow: Yup.number().nullable().defined(),
        LatestFlowMeasure: Yup.string().nullable().defined(),
        FlowMonitoringStatus: Yup.boolean().required('Required'),
        Geom: Yup.mixed<firebase.firestore.GeoPoint>().nullable().defined(),
        Elevation: Yup.number().required('Required'),
        Vnotch: Yup.array<IRiverVNotch>().required('Required'),
        IsActive: Yup.boolean().nullable().defined(),
        IsSent: Yup.boolean().required('Required'),
    });
}
