import { Card, FormControl, Typography } from '@material-ui/core';
import { Field, FieldProps } from 'formik';
import { Moment } from 'moment';
import React from 'react';
import OutlinedStadiumButton from '../../../../customComponents/button/OutlinedStadiumButton';
import StadiumButton from '../../../../customComponents/button/StadiumButton';
import FormTabDateSelector from '../../../../customComponents/form/FormTabDateSelector';
import TextFormField from '../../../../customComponents/form/TextFormField';
import FormCheckbox from '../../../../customComponentV2/FormCheckbox';
import { getState } from '../../../../../store';
import { IDamSafetyInspectionFormValues } from '../../../../../@types/model/dam/damSafetyInspection';

interface IDamSafetyInspectionEditDialogGeneralTabProps {
    isLoading : boolean;
    initialValues : IDamSafetyInspectionFormValues;

    onNextClick ?: () => void;
    onCancelClick ?: () => void;
}

interface IDamSafetyInspectionEditDialogGeneralTabState {}

export default class DamSafetyInspectionEditDialogGeneralTab extends React.PureComponent<IDamSafetyInspectionEditDialogGeneralTabProps, IDamSafetyInspectionEditDialogGeneralTabState> {
    constructor(props : IDamSafetyInspectionEditDialogGeneralTabProps) {
        super(props);
        this.state = {};
    }

    public readonly render = () => {
        const session = getState().auth.session;
        const { onNextClick, onCancelClick } = this.props;
        return (
            <div className='fdc flx1 ml15 mr15 mt15'>
                <Field 
                    id='date'
                    name='date'
                >
                    {
                        (params : FieldProps<Moment>) => <FormTabDateSelector {...params} />
                    }
                </Field>
                <div className='fdr ais jcfs mt12'>
                    <div className='fdc flx1 ais'>
                        <Card className='fdc'>
                            <div className={'jcc aic wfill dvblg h50'}>
                                <Typography className={'fs14 fw400 cw'}>
                                    EMPLOYEE DETAILS
                                </Typography>
                            </div>
                            <div className={'fdc pb20 pt20 jcc ais'}>
                                <Typography className={'aic jcc fs13 fw500 cpd mb12'}>
                                    DATA RECORDER{'\u2019'}S PERSONEL NUMBER
                                </Typography>
                                <div className='fdr mr30 ml30'>
                                    <TextFormField
                                        inputProps={{
                                            className: 'cp fwb',
                                            style: {
                                                textAlign: 'center',
                                            },
                                        }}
                                        id={'employeeNumber'}
                                        name={'employeeNumber'}
                                        fullWidth
                                        required
                                        autoComplete='off'
                                    />
                                </div>
                            </div>
                            <div className={'fdc pb20 pt20 bcg2 jcc ais'}>
                                <Typography className={'aic jcc fs13 fw500 cpd mb12'}>
                                    DATA RECORDER{'\u2019'}S PERSONEL NAME
                                </Typography>
                                <div className='fdr mr30 ml30'>
                                    <TextFormField
                                        inputProps={{
                                            className: 'cp fwb',
                                            style: {
                                                textAlign: 'center',
                                            },
                                        }}
                                        id={'employeeName'}
                                        name={'employeeName'}
                                        fullWidth
                                        required
                                        autoComplete='off'
                                    />
                                </div>
                            </div>
                        </Card>
                        <Card className='fdc mt12'>
                            <div className={'jcc aic wfill dbcsdg h50'}>
                                <Typography className={'fs14 fw400 cw'}>
                                    IS REPORT ACTIVE
                                </Typography>
                            </div>
                            <div className={'fdc jcc ais'}>
                                <div className='fdr mr30 ml30'>
                                    <FormCheckbox
                                        name={`isActive`}
                                        label={'Is Active?'}
                                        disabled={this.props.initialValues.isActive || !session?.employee.IsDamAdmin}
                                    />
                                </div>
                            </div>
                        </Card>
                    </div>
                    <span className='w30' />
                    <div className='fdc flx1 ais'>
                        <Card className='fdc'>
                            <div className={'jcc aic wfill dvbg h50'}>
                                <Typography className={'fs16 fw400 cw'}>
                                    DOCUMENT DETAILS
                                </Typography>
                            </div>
                            <div className={'fdc pb20 pt20 jcc ais'}>
                                <Typography className={'aic jcc fs13 fw500 cpd mb12'}>
                                    NAME OF DAM
                                </Typography>
                                <div className='fdr mr30 ml30'>
                                    <TextFormField
                                        inputProps={{
                                            className: 'cp fwb',
                                            style: {
                                                textAlign: 'center',
                                            },
                                        }}
                                        id={'name'}
                                        name={'name'}
                                        fullWidth
                                        required
                                        autoComplete='off'
                                    />
                                </div>
                            </div>
                            <div className={'fdc pb20 pt20 bcg2 jcc ais'}>
                                <Typography className={'aic jcc fs13 fw500 cpd mb12'}>
                                    NAME OF OWNER
                                </Typography>
                                <div className='fdr mr30 ml30'>
                                    <TextFormField
                                        inputProps={{
                                            className: 'cp fwb',
                                            style: {
                                                textAlign: 'center',
                                            },
                                        }}
                                        id={'owner'}
                                        name={'owner'}
                                        fullWidth
                                        required
                                        autoComplete='off'
                                    />
                                </div>
                            </div>
                            <div className={'fdc pb20 pt20 jcc ais'}>
                                <Typography className={'aic jcc fs13 fw500 cpd mb12'}>
                                    INSPECTION NUMBER
                                </Typography>
                                <div className='fdr mr30 ml30'>
                                    <FormControl fullWidth required>
                                        <TextFormField
                                            inputProps={{
                                                className: 'cp fwb',
                                                style: {
                                                    textAlign: 'center',
                                                },
                                            }}
                                            id='inspectionNumber'
                                            name='inspectionNumber'
                                            fullWidth
                                            required
                                            type='number'
                                            autoComplete='off'
                                        />
                                    </FormControl>
                                </div>
                            </div>
                        </Card>
                        <span className='flx1' />
                        <div className='fdr jcfe mt10'>
                            <div className='mr15'>
                                <OutlinedStadiumButton onClick={onCancelClick}>
                                    Cancel
                                </OutlinedStadiumButton>
                            </div>
                            <div>
                                <StadiumButton onClick={onNextClick}>
                                    Next
                                </StadiumButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}
