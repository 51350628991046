import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { IOptionType } from '../../@types/model/optionType';
import CustomDropdown from './CustomDropdown';

export type DropdownValueType = IOptionType | Array<IOptionType> | null;

interface IControlledDropdownProps{
    name : string;
    label : string;
    control : Control<any, any>;
    defaultValues : IOptionType | Array<IOptionType> | null;
    onChange ?: (v : DropdownValueType) => void;
    options : Array<IOptionType>;
    disabled ?: boolean;
}

const CustomControlledDropdown = (props : IControlledDropdownProps) => {
    return (
        <Controller
            name={props.name}
            control={props.control}
            defaultValue={props.defaultValues}
            render={(x) => (
                <CustomDropdown 
                name={x.field.name}
                onChange={(
                    value : DropdownValueType) : void => {
                    x.field.onChange(value);
                    if (props.onChange) props.onChange(value);
                }}
                options={props.options} 
                label={props.label} 
                disabled={props.disabled}
                value={x.field.value}
                isLoading={false}
                defaultValue={Array.isArray(props.defaultValues) ? null : props.defaultValues}
            />
            )}
        />
    )
}

export default CustomControlledDropdown;