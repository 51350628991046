import React from 'react';
import { connect } from 'react-redux';
import { IRootState } from '../../@types/redux';
import Dialog from '@material-ui/core/Dialog';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import { IUserSession } from '../../@types/employee';
import Tooltip from '@material-ui/core/Tooltip';
import PrimaryTabs from '../customComponents/tab/PrimaryTabs';
import PrimaryTab from '../customComponents/tab/PrimaryTab';
import DamFunctions from '../../store/dam/functions';
import { IDam } from '../../@types/model/dam/dam';
import DamSeepage from './view/DamSeepageView';
import DamSummary from './view/DamSummaryView';
import DamDivision from './view/DamDivisionView';
import DamLocation from './view/DamLocationView';
import DamReg from './view/DamRegView';
import DamSafety from './view/DamSafetyView';
import DamReading from './view/DamReadingView';
import TabViews from '../customComponents/tab/TabViews';
import DamMasterData from './view/DamMasterData';
import DamImagesView from './view/DamImagesView';
import DamAerialView from './view/DamAerialView';
import DamCommentsView from './view/DamCommentView';
import DamInspectionView from './view/DamInspectionView';
import DamTransformerView from './view/DamTransformerView';

interface IDamInfoProps {
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    dam ?: IDam;
    isLoadingDams : boolean;

    session ?: IUserSession | null;
}

interface IDamInfoState {
    tabIndex : number;
}

class DamInfo extends React.PureComponent<IDamInfoProps, IDamInfoState> {
    constructor(props : IDamInfoProps) {
        super(props);

        this.state = {
            tabIndex: 0,
        };
    }

    private readonly onClose = () => {
        DamFunctions.setSelected();
    };

    private readonly onTabChange = (event : React.ChangeEvent<unknown>, value : number) => {
        this.setState({
            tabIndex: value,
        });
    };

    public readonly render = () => {
        const { dam, fullWidth, transition, maxWidth, fullScreen, session, isLoadingDams } = this.props;
        const { tabIndex } = this.state;
        
        return (
            <Dialog
                open={!!dam}
                onClose={this.onClose}
                aria-labelledby='dam-info-dialog-title'
                aria-describedby='dam-info-description'
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                TransitionComponent={transition}
                transitionDuration={500}
                fullScreen={fullScreen}>
                <AppBar className='posr' position='static' elevation={0}>
                    <Toolbar variant='dense'>
                        {
                            dam &&
                            <Typography variant='h6' color='inherit' className='flx1'>
                                {dam.code} - {dam.name}
                            </Typography>
                        }
                        <span className={'flx1'} />
                        <Tooltip title='Close'>
                            <div>
                                <IconButton color='inherit' onClick={this.onClose} aria-label='Close'>
                                    <Icon>close</Icon>
                                </IconButton>
                            </div>
                        </Tooltip>
                    </Toolbar>
                    <PrimaryTabs
                        value={tabIndex}
                        onChange={this.onTabChange}
                        aria-label='Info Tabs'
                        variant='scrollable'
                    >
                        <PrimaryTab label='SUMMARY' />
                        <PrimaryTab label='MASTER DATA' />
                        <PrimaryTab label='DIVISION INFO' />
                        <PrimaryTab label='ELECTRICAL POINT' />
                        <PrimaryTab label='LOCATION' />
                        {
                            session?.employee.IsDamRegReportUser &&
                            <PrimaryTab label='DAM REGISTRATION DETAILS' />
                        }
                        {
                            session?.employee.IsDamSafetyReportUser &&
                            <PrimaryTab label='DAM SAFETY INSPECTION REPORT' />
                        }
                        <PrimaryTab label='MONTHLY READING' />
                        {
                            dam?.damVNotch?.length &&
                            <PrimaryTab label='WEEKLY SEEPAGE READING' />
                        }
                        <PrimaryTab label='IMAGES' />
                        <PrimaryTab label='AERIAL IMAGES' />
                        <PrimaryTab label='DAM SAFETY QUARTERLY INSPECTIONS' />
                        <PrimaryTab label='COMMENTS' />
                    </PrimaryTabs>
                </AppBar>
                <div className={'fdc flx1 hfill'}>
                    {
                        dam && session &&
                        <TabViews index={tabIndex}>
                            <DamSummary session={session} dam={dam} isLoading={isLoadingDams} />
                            <DamMasterData dam={dam} />
                            <DamDivision dam={dam} />
                            <DamTransformerView dam={dam} />
                            <DamLocation dam={dam} />
                            {
                                session.employee.IsDamRegReportUser &&
                                <DamReg session={session} dam={dam} isLoading={isLoadingDams} />
                            }
                            {
                                session.employee.IsDamSafetyReportUser &&
                                <DamSafety session={session} dam={dam} isLoading={isLoadingDams} />
                            }
                            <DamReading session={session} dam={dam} />
                            {
                                !!dam.damVNotch?.length &&
                                <DamSeepage session={session} dam={dam} />
                            }
                            <DamImagesView dam={dam} />
                            <DamAerialView dam={dam} session={session} />
                            <DamInspectionView dam={dam} />
                            <DamCommentsView dam={dam} session={session} />
                        </TabViews>
                    }
                </div>
            </Dialog>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        dam: state.dam.selectedDam,
        isLoadingDams: state.dam.loading,
        session: state.auth.session,
    };
};

export default connect(
    mapStateToProps,
)(DamInfo);
