import Paper from '@material-ui/core/Paper';
import { Formik } from 'formik';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import { ISPULine } from '../../../../@types/model/transformer/tariffs';
import '../../../../style/transformer/SPUCapturing.scss';
import generalFunctions from '../../../../store/general/functions';
import SPUCapturingFormTableRow from '../form/SPUTableFormRow';
import OutlinedStadiumButton from '../../../customComponents/button/OutlinedStadiumButton';
import StadiumButton from '../../../customComponents/button/StadiumButton';
import { ITransformerInvoice } from '../../../../@types/model/transformer/transformerInvoice';
import navFunctions from '../../../../store/nav/functions';
import moment from 'moment';
import { DATE_INPUT_FORMAT_DEFAULT } from '../../../../appConstants';
import { createSelector } from 'reselect';
import FormCheckbox from '../../../customComponentV2/FormCheckbox';

interface ISPUCapturingTableProps {
    multi ?: boolean;
    invoices : Array<ITransformerInvoice<ISPULine>>;

    onSubmit ?: (invoices : Array<ITransformerInvoice<ISPULine>>) => void;

    selectedEpNumber ?: string;
    disabledTransformers : Array<string>;

    onCancelClick ?: () => void;
}

interface ISPUCapturingTableState {
}

export default class SPUCapturingTable extends React.PureComponent<ISPUCapturingTableProps, ISPUCapturingTableState> {
    constructor(props : ISPUCapturingTableProps) {
        super(props);
        this.state = {
            lines: [],
        };
    }

    public readonly componentDidMount = () => {
        this.loadData();
    };

    public readonly loadData = () => {

        try {
            const lines : Array<ISPULine> = [];

            this.props.invoices.forEach((invoice) => {
                invoice.lines.forEach(line => lines.push(line as ISPULine));
            });
            
            this.setState({
                lines,
            });
        } catch (ex) {
            generalFunctions.generalShowError(ex, `${ex}`);
        }

    };

    public readonly onSubmit = async (values : Array<ITransformerInvoice<ISPULine>>) => {
        if (values.some(invoice => invoice.paymentDueDate && !moment.utc(invoice.paymentDueDate, DATE_INPUT_FORMAT_DEFAULT, true).isValid())) {

            generalFunctions.generalShowErrorSnackbar('Invalid due date');
            return;
        }
        
        if (this.props.onSubmit) this.props.onSubmit(values);
    };

    public readonly onCancelClick = () => {
        if (this.props.onCancelClick) {
            this.props.onCancelClick();
            return;
        }
        navFunctions.navReplacePath('/transformer/capturing', 'type=spu');
    };

    public readonly getInvoices = (state : ISPUCapturingTableState, props : ISPUCapturingTableProps) => props.invoices;
    public readonly getSortedInvoices = createSelector(
        [this.getInvoices],
        (invoices) => {
            return invoices
                .sort((a, b) => Number(a.accountNumber) - Number(b.accountNumber));
        },
    );

    public readonly render = () => {
        const { multi, selectedEpNumber, disabledTransformers } = this.props;
        const invoices = this.getSortedInvoices(this.state, this.props);
        return (
            <div className='fdc flx1 bct'>
                <Formik
                    enableReinitialize
                    initialValues={invoices}
                    onSubmit={this.onSubmit}
                >
                    {
                        ({
                            values,
                            isSubmitting,
                            handleChange,
                            handleBlur,
                            handleReset,
                            setFieldValue,
                            dirty,
                        }) => (
                            <form id='spu-form' className='fdc flx1' onSubmit={() => this.onSubmit(values)} onReset={handleReset}>
                                <Paper className='bct oyo'>
                                    {
                                        this.props.invoices.length === 1 &&
                                        <FormCheckbox
                                            name={`0.isActive`}
                                            label={'Is Active?'}
                                            disabled={this.props.invoices[0].isActive}
                                        />
                                    }
                                    <Table className='spu-capture-table'>
                                        <TableHead>
                                            <TableRow className='header-row'>
                                                <TableCell align='center' className='waypoint'>
                                                    Way Point
                                                </TableCell>
                                                <TableCell align='center' className='account'>
                                                    Account No
                                                </TableCell>
                                                <TableCell align='center' className='total-usage'>
                                                    Total Usage
                                                </TableCell>
                                                <TableCell align='center' className=''>
                                                    Number of Days
                                                </TableCell>
                                                <TableCell align='center' className=''>
                                                    Due Date
                                                </TableCell>
                                                <TableCell align='center' className=''>
                                                    Credit Balance
                                                </TableCell>
                                                <TableCell align='center' className=''>
                                                    Total Charges
                                                </TableCell>
                                                <TableCell align='center' className=''>
                                                    Total VAT
                                                </TableCell>
                                                <TableCell align='center' className=''>
                                                    Invoice Total
                                                </TableCell>
                                                <TableCell align='center' className=''>
                                                    Billed Total
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {
                                            values
                                                .map((invoice, index) => (
                                                    <TableBody id={invoice.accountNumber}
                                                        className={`${selectedEpNumber === invoice.epNumber ? 'selected' : ''} ${invoice.lines.length > 1 ? 'multi' : ''} invoice`}
                                                        key={index}
                                                    >
                                                        {
                                                            invoice.lines.map((line, lineIndex) => (
                                                                <SPUCapturingFormTableRow key={`value_${invoice.epNumber}_${lineIndex}`}
                                                                    invoice={invoice}
                                                                    disabled={disabledTransformers.includes(invoice.epNumber)}
                                                                    index={index}
                                                                    lineIndex={lineIndex}
                                                                    line={line}
                                                                    isSubmitting={isSubmitting}
                                                                    handleChange={handleChange}
                                                                    handleBlur={handleBlur}
                                                                    setFieldValue={setFieldValue}
                                                                    multi={multi}
                                                                />
                                                            ))
                                                        }
                                                        
                                                    </TableBody>
                                                ))
                                        }
                                    </Table>
                                </Paper>
                                <span className='flx1' />
                                <div className='fdr jcfe aife mt15'>
                                    <OutlinedStadiumButton className='mr16' variant='text' type='button' onClick={this.onCancelClick} disabled={isSubmitting}>
                                            CANCEL
                                    </OutlinedStadiumButton>
                                    <StadiumButton type='submit' disabled={isSubmitting || !dirty}>
                                            SAVE
                                    </StadiumButton>
                                </div>
                            </form>
                        )
                    }
                </Formik>
            </div>
        );
    };
}
