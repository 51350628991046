import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import DialogContent from '@material-ui/core/DialogContent';
import AppFunctionsService from '../../../../services/appFunctionsService';
import { IRootState } from '../../../../@types/redux';
import { connect } from 'react-redux';
import { IUserSession } from '../../../../@types/employee';
import GeneralFunctions from '../../../../store/general/functions';
import StadiumButton from '../../../customComponents/button/StadiumButton';
import { CircularProgress, Tab, Tabs } from '@material-ui/core';
import TabViews from '../../../customComponents/tab/TabViews';
import Dropzone from 'react-dropzone';
import FileEmbedView from '../../../customComponents/embed/FileEmbedView';
import FormikForm from '../../../customComponents/form/FormikForm';
import DamSafetyInspectionHelper, { IDamSafetyInspection, IDamSafetyInspectionFormValues } from '../../../../@types/model/dam/damSafetyInspection';
import { IDam } from '../../../../@types/model/dam/dam';
import DamSafetyInspectionEditDialogDetailsTab from './tabs/DetailsTab';
import DamSafetyInspectionEditDialogGeneralTab from './tabs/GeneralTab';
import DamSafetyInspectionEditDialogCommentsTab from './tabs/CommentsTab';
import firebaseApp from '../../../../services/firebaseService';
import firebase from 'firebase/app';
import { EnumFileUploadQueueType } from '../../../../@types/model/files/enum';

interface IEditDamSafetyInspectionProps {
    isOpen : boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;
    damSafetyInspection ?: IDamSafetyInspection;
    session ?: IUserSession | null;
    dam ?: IDam;
    onDismiss : () => void;
}

interface IEditDamSafetyInspectionState {
    isLoading : boolean;
    file : File | null;
    tabIndex : number;
    initialValues : IDamSafetyInspectionFormValues | null;
}

class EditDamSafetyInspectionComponent extends React.Component<IEditDamSafetyInspectionProps, IEditDamSafetyInspectionState> {
    constructor(props : IEditDamSafetyInspectionProps) {
        super(props);
        this.state = {
            isLoading: false,
            file: null,
            tabIndex: 0,
            initialValues: null,
        };
    }

    public readonly componentDidUpdate = (prevProps : IEditDamSafetyInspectionProps, prevState : IEditDamSafetyInspectionState) => {
        if (!prevProps.isOpen && this.props.isOpen) {
            const { session } = this.props;
            this.setState({
                isLoading: false,
                file: null,
                tabIndex: 0,
                initialValues: {
                    ...DamSafetyInspectionHelper.initialFormValues(this.props.damSafetyInspection, this.props.dam),
                    employeeName: this.props.damSafetyInspection?.employeeName ?? session?.employee.Name ?? null,
                    employeeNumber: this.props.damSafetyInspection?.employeeNumber ?? session?.employee.EmployeeNumber ?? null,
                },
            });
        }
    };

    private readonly onTabChange = (event : React.ChangeEvent<unknown>, value : number) => {
        this.setState({
            tabIndex: value,
        });
    };

    private readonly save = async (formValues : IDamSafetyInspectionFormValues) => {
        const { damSafetyInspection } = this.props;
        const { 
            file,
        } = this.state;

        const ref = DamSafetyInspectionHelper.doc(damSafetyInspection?.id);

        try {
            this.setState({
                isLoading: true,
            });
            
            await firebaseApp.firestore().runTransaction(async (transaction) => {
                const { session, dam } = this.props;
        
                if (!session) throw new Error('Session Required');
                if (!dam) throw new Error('Dam Required');
                if (!file && !damSafetyInspection) throw new Error('Document Required');
    
                /**
                 * Have to get docs first.
                 */
                const doc = await transaction.get(ref);
    
                const data = doc.data();
    
                transaction.set(ref, {
                    date: formValues.date.valueOf(),
                    documentName: file?.name ?? data?.documentName ?? null,
                    documentURL: !file ? (data?.documentURL ?? null) : '',
                    employeeName: formValues.employeeName ?? '',
                    employeeNumber: formValues.employeeNumber ?? '',
                    inspectionNumber: formValues.inspectionNumber ?? 0,
                    name: formValues.name,
                    owner: formValues.owner,
                    guid: data?.guid ?? formValues.guid,
                    createdBy: data?.createdBy ?? session.firebaseUser.uid,
                    createdByName: data?.createdByName ?? session.employee.Name,
                    createdByEmployee: data?.createdByEmployee ?? session.employee.EmployeeNumber ?? '',
                    createdOn: data?.createdOn ?? firebase.firestore.Timestamp.now().toMillis(),
                    updatedBy: session.firebaseUser.uid,
                    updatedByName: session.employee.Name,
                    updatedByEmployee: session.employee.EmployeeNumber ?? '',
                    updatedOn: firebase.firestore.Timestamp.now().toMillis(),
                    isSent: false,
                    isActive: formValues.isActive,
                    isWeb: data?.isWeb ?? true,
                    damRef: dam.id,
                    damCode: dam.code,
                    comments: formValues.comments.map((x) => ({
                        createdBy: x.createdBy,
                        comment: x.comment,
                        createdByEmployee: x.createdByEmployee,
                        createdByName: x.createdByName,
                        createdOn: x.createdOn.valueOf(),
                    })),
                    details: formValues.details.map((x) => ({
                        ...x,
                        commenceDate: x.commenceDate?.valueOf() ?? null,
                        completionDate: x.completionDate?.valueOf() ?? null,
                    })),
                }, {
                    merge: true,
                });
    
                if (file) {
                    await DamSafetyInspectionHelper.uploadFile(
                        data?.damCode ?? dam.code,
                        file,
                        {
                            collection: DamSafetyInspectionHelper.COLLECTION_NAME,
                            fieldName: 'documentURL',
                            fileType: EnumFileUploadQueueType.DamSafety.toString(),
                            refGuid: formValues.guid,
                            thumbnailFieldName: '',
                        }
                    );
                }
            });

            this.props.onDismiss();

            GeneralFunctions.generalShowSuccessSnackbar('Success');
        } catch (ex) {
            GeneralFunctions.generalShowError(ex, 'Error saving inspection.');
        } finally {
            this.setState({
                isLoading: false,
            });
        }
        
    };

    private readonly onSubmit = (formValues : IDamSafetyInspectionFormValues) => {
        this.save(formValues);
    };

    private readonly onFileDrop = (acceptedFiles : Array<File>) => {
        if (!acceptedFiles.length) return;

        this.setState({
            file: new File([acceptedFiles[0].slice()], `${acceptedFiles[0].name}`, {
                type: acceptedFiles[0].type,
            }),
        });
    };

    private readonly onNextClick = () => {
        this.setState({
            tabIndex: this.state.tabIndex + 1,
        });
    };

    private readonly onBackClick = () => {
        this.setState({
            tabIndex: this.state.tabIndex - 1,
        });
    };
    

    public readonly render = () => {
        const { transition, damSafetyInspection, isOpen, onDismiss } = this.props;
        const { isLoading, tabIndex, file, initialValues } = this.state;

        return (
            <React.Fragment>
                <Dialog
                    open={isOpen}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={onDismiss}
                    fullScreen
                    fullWidth
                    aria-labelledby='dam-calibration-edit-dialog-title'
                    aria-describedby='dam-info-edit-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            {
                                !!damSafetyInspection &&
                                <Typography variant='h5' color='inherit'>
                                    Edit Dam Safety Inspection - {AppFunctionsService.formatDateTimeToDatePicker(damSafetyInspection.date)}
                                </Typography>
                            }
                            {
                                !damSafetyInspection &&
                                <Typography variant='h5' color='inherit'>
                                    Add Dam Safety Inspection
                                </Typography>
                            }
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' disabled={isLoading} onClick={onDismiss} aria-label='Close'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    {
                        initialValues &&
                        <FormikForm
                            onSubmit={this.onSubmit}
                            className='fdc hfill bcg0'
                            validateOnMount
                            initialValues={initialValues}
                            validationSchema={DamSafetyInspectionHelper.formSchema()}
                            enableReinitialize
                        >
                            {
                                (props) => (
                                    <>
                                        <DialogContent className='fdc hfill p0'>
                                            {
                                                isLoading &&
                                                <div className='fdc flx1 aic jcc'>
                                                    <CircularProgress />
                                                </div>
                                            }
                                            {
                                                !isLoading &&
                                                <div className='fdr'>
                                                    <div className='fdc w804'>
                                                        <div className={'jcfs aic wfill dvbg h50'}>
                                                            <Typography className={'ml15 fs14 fw500 cw '}>
                                                                PDF DOCUMENT
                                                            </Typography>
                                                            <span className='flx1' />
                                                        </div>
                                                        <div className='fdc h1132 wfill'>
                                                            <Dropzone onDrop={this.onFileDrop} accept={'application/pdf,image/*'}>
                                                                {({ getRootProps, getInputProps }) => (
                                                                    <section className='fdc flx1'>
                                                                        <div className='fdc flx1' {...getRootProps()}>
                                                                            <input disabled={isLoading} multiple={false} {...getInputProps()} />
                                                                            
                                                                            {
                                                                                !file &&
                                                                                <div className='fdr flx1 jcc aic'>
                                                                                    <Typography className='cg0' variant='body2'>
                                                                                        DRAG &amp; DROP FILE
                                                                                    </Typography>
                                                                                </div>
                                                                            }
                                                                            
                                                                            {
                                                                                file &&
                                                                                <FileEmbedView file={file} />
                                                                            }
                                                                        </div>
                                                                    </section>
                                                                )}
                                                            </Dropzone>
                                                        </div>
                                                    </div>
                                                    <div className='fdc flx1'>
                                                        <Tabs
                                                            value={tabIndex}
                                                            onChange={this.onTabChange}
                                                            aria-label='Info Tabs'
                                                            className={'h50'}
                                                            variant={'fullWidth'}
                                                            indicatorColor={'primary'}
                                                        >
                                                            <Tab label='GENERAL INFO' value={0} className={'cdg'} fullWidth/>
                                                            <Tab label='DETAILS' value={1} className={'cdg'} fullWidth/>
                                                            <Tab label='COMMENTS' value={2} className={'cdg'} fullWidth/>
                                                        </Tabs>
                                                        <div className={'fdc flx1 hfill mb15'}>
                                                            <TabViews index={tabIndex}>
                                                                <DamSafetyInspectionEditDialogGeneralTab
                                                                    isLoading={isLoading}
                                                                    onNextClick={this.onNextClick}
                                                                    onCancelClick={onDismiss}
                                                                    initialValues={initialValues}
                                                                />
                                                                <DamSafetyInspectionEditDialogDetailsTab
                                                                    details={props.values.details}
                                                                    isLoading={isLoading}
                                                                    onNextClick={this.onNextClick}
                                                                    onBackClick={this.onBackClick}
                                                                />
                                                                <DamSafetyInspectionEditDialogCommentsTab
                                                                    comments={props.values.comments}
                                                                    isLoading={isLoading}
                                                                    onSaveClick={props.submitForm}
                                                                    onBackClick={this.onBackClick}
                                                                    isValid={props.isValid}
                                                                />
                                                            </TabViews>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </DialogContent>
                                    </>
                                )
                            }
                        </FormikForm>
                    }
                </Dialog>
            </React.Fragment>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        session: state.auth.session,
    };
};

const EditDamSafetyInspection = connect(
    mapStateToProps,
)(EditDamSafetyInspectionComponent);

export default EditDamSafetyInspection;