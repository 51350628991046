import React from 'react';
import riverFunctions from '../../store/river/functions';
import { IRootState } from '../../@types/redux';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import { createSelector } from 'reselect';
import Toolbar from '@material-ui/core/Toolbar';
import Card from '@material-ui/core/Card';
import MaterialTable from '../customComponents/materialTable/Table';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import lodash from 'lodash';
import RiverInfo from './Info';
import { Transitions } from '../customComponents/animations/Transitions';
import navFunctions from '../../store/nav/functions';
import RiverNewButton from './button/NewButton';
import appFunctionsService from '../../services/appFunctionsService';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import RiverFilterSelector from '../customComponents/selector/river/FilterSelector';
import { IUserSession } from '../../@types/employee';
import { CSVLinkProps } from '../../@types/csv';
import { IRiver } from '../../@types/model/river/river';

interface IRiverListProps {
    rivers : Array<IRiver>;
    isLoadingRivers : boolean;
    session ?: IUserSession | null;
}

interface IRiverListState {
    sortList : Array<{ field : string; direction : 'asc' | 'desc' }>;
    name : string;
    division : string;
    code : string;
}

class RiverList extends React.Component<IRiverListProps, IRiverListState> {
    private readonly csvLink : React.RefObject<CSVLinkProps & HTMLAnchorElement>;
    constructor(props : IRiverListProps) {
        super(props);

        this.state = {
            sortList: [],
            name: '',
            division: '',
            code: '',
        };

        this.csvLink = React.createRef<CSVLinkProps & HTMLAnchorElement>();
    }

    public readonly componentDidMount = () => {
        riverFunctions.getList();
    };

    private readonly onSortChanged = (sortList : Array<{ field : string; direction : 'asc' | 'desc' }>) => {
        this.setState({
            sortList,
        });
    };

    public readonly getData = (state : IRiverListState, props : IRiverListProps) => props.rivers;
    public readonly getSortList = (state : IRiverListState) => state.sortList;
    public readonly getName = (state : IRiverListState) => state.name;
    public readonly getCode = (state : IRiverListState) => state.code;
    public readonly getDivision = (state : IRiverListState) => state.division;

    public readonly getFilteredData = createSelector(
        [this.getData, this.getSortList, this.getName, this.getCode, this.getDivision],
        (rivers, sortList, name, code, division) => {
            let sortedList = rivers.slice().filter(n => (n.IsActive === undefined || n.IsActive))
                .filter(n => n.WRNumber.toLowerCase().includes(code.toLowerCase()))
                .filter(n => (n.Division ?? '').toLowerCase().includes(division.toLowerCase()))
                .filter(n => n.RiverName.toLowerCase().includes(name.toLowerCase()));
            
            if (sortList.length === 0) {
                return sortedList.sort((a, b) => parseInt(a.WRNumber.substring(2)) - parseInt(b.WRNumber.substring(2)));
            }

            sortList.forEach((n) => {
                if (n.field === 'WRNumber') {
                    sortedList = sortedList.sort((a, b) => parseInt(b.WRNumber.substr(2)) - parseInt(a.WRNumber.substr(2)) * (n.direction === 'desc' ? 1 : -1));
                } else {
                    sortedList = lodash.orderBy(sortedList, n.field, n.direction);
                }
            });

            return sortedList;
        },
    );

    public readonly getCSVData = createSelector(
        [this.getFilteredData],
        (boreholes) => {
            return boreholes.map(n => ({
                WRNumber: n.WRNumber,
                RiverName: n.RiverName,
                Division: n.Division,
                LatestFlow: n.LatestFlow,
                LatestFlowType: n.LatestFlowType,
                CreatedOn: appFunctionsService.formatDateTime(n.CreatedOn),
                UpdatedOn: appFunctionsService.formatDateTime(n.UpdatedOn),
                Latitude: !n.Geom ? 0 : n.Geom.latitude,
                Longitude: !n.Geom ? 0 : n.Geom.longitude,
            }));
        },
    );

    public readonly onInfoClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const river = this.props.rivers.slice().find(x => x.WRNumber === event.currentTarget.value);

        riverFunctions.setSelected(river);
    };

    public readonly onMapClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const river = this.props.rivers.slice().find(x => x.WRNumber === event.currentTarget.value);

        if (river) {
            navFunctions.navRiverMap(river.WRNumber);
        }
    };

    private readonly onCSVClick = () => {
        if (this.csvLink.current) {
            this.csvLink.current.link.click();
        }
    };

    public readonly renderFlowMonitoringStatus = (row : IRiver) => {
        if (row.FlowMonitoringStatus) {
            return (
                <div className={'jcfs aifs'}>
                    <Icon className={'cp'}>
                        check_circle
                    </Icon>
                </div>

            );
        }
        return (
            <div className={'jcfs aifs'}>
                <Icon className={'cr'}>
                    cancel
                </Icon>
            </div>
        );
    };

    private readonly onFilterChange = (name : string | null, code : string | null, division : string | null) => {
        this.setState({
            name: name ?? '',
            code: code ?? '',
            division: division ?? '',
        });
    };

    public readonly render = () => {
        const { isLoadingRivers, session } = this.props;
        const { name, code, division } = this.state;
        const rivers = this.getFilteredData(this.state, this.props);

        const csvName = moment().valueOf();
        const csvData = this.getCSVData(this.state, this.props);

        return (
            <div className={'fdc flx1 p10 mh0 mw0 bcg0 pr15 pl15'}>
                <div className={'flx1 fdc mh0 mw0'}>
                    <Toolbar>
                        <Typography variant='h5' className='fs20 fw500 cdg'>
                        RIVERS
                        </Typography>
                        <span className={'flx4'} />
                        <div className='flx1'>
                            <RiverFilterSelector
                                id='river_filter'
                                onChange={this.onFilterChange}
                                name={name}
                                code={code}
                                division={division}
                                label='Filter By...'
                                disabled={isLoadingRivers}
                            />
                        </div>
                        <div className={'fdr pt5 jcfe aic'}>
                            <div className={'fdr pr15'}>
                                <Typography className={'fs16 fw500 cp'}>
                                    Total:
                                </Typography>
                                <Typography className={'fs16 fw600 cp'}>
                                    {rivers.length}
                                </Typography>
                            </div>
                        </div>
                    </Toolbar>
                    <Card className={'flx1 fdc'}>
                        <MaterialTable<IRiver>
                            id='riversTable'
                            data={rivers}
                            isLoading={isLoadingRivers}
                            rowsPerPage={50}
                            externalSort
                            onSortChange={this.onSortChanged}
                            onExportCsvClick={!session?.employee.IsRiverExport ? undefined : this.onCSVClick}
                            columns={[{
                                header: '',
                                width: 100,
                                paddingRight: 4,
                                renderCell: row => (
                                    <div className='aic'>
                                        <Tooltip title='Info'>
                                            <div>
                                                <IconButton value={row.WRNumber} onClick={this.onInfoClick}>
                                                    <Icon>
                                                        list
                                                    </Icon>
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                        <Tooltip title='Map'>
                                            <div>
                                                <IconButton value={row.WRNumber} onClick={this.onMapClick} disabled={!row.Geom}>
                                                    <Icon>
                                                    map
                                                    </Icon>
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                    </div>
                                ),
                            }, {
                                header: 'Division',
                                field: 'Division',
                                width: 175,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'WRNumber',
                                field: 'WRNumber',
                                width: 150,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Name',
                                field: 'RiverName',
                                width: 150,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Latest Flow',
                                field: 'LatestFlow',
                                width: 150,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Latest Flow Type',
                                field: 'LatestFlowType',
                                width: 150,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Flow Monitoring',
                                field: 'FlowMonitoringStatus',
                                enableSort: true,
                                enableFilter: true,
                                renderCell: row => this.renderFlowMonitoringStatus(row),
                            }]}
                        />
                    </Card>
                    <RiverInfo fullScreen transition={Transitions.Up} />
                    <div className={'fdrr mt15 mr15'}>
                        {
                            session?.employee.IsRiverAdmin &&
                            <RiverNewButton />
                        }
                    </div>
                    {
                        session?.employee.IsRiverExport &&
                        <CSVLink
                            data={csvData}
                            headers={[
                                {
                                    key: 'WRNumber',
                                    label: 'WR Number',
                                },
                                {
                                    key: 'RiverName',
                                    label: 'Name',
                                },
                                {
                                    key: 'Division',
                                    label: 'Division',
                                },
                                {
                                    key: 'Latitude',
                                    label: 'Latitude',
                                },
                                {
                                    key: 'Longitude',
                                    label: 'Longitude',
                                },
                                {
                                    key: 'LatestFlow',
                                    label: 'Latest Flow',
                                },
                                {
                                    key: 'LatestFlowType',
                                    label: 'Latest Flow Type',
                                },
                                {
                                    key: 'CreatedOn',
                                    label: 'Created On',
                                },
                                {
                                    key: 'UpdatedOn',
                                    label: 'Updated On',
                                },
                            ]}
                            filename={`${csvName}_river.csv`}
                            className='dn'
                            ref={this.csvLink}
                            target='_blank'/>
                    }
                    
                </div>
            </div>);
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        rivers: state.river.rivers,
        isLoadingRivers: state.river.loading,
        session: state.auth.session,
    };
};

export default connect(
    mapStateToProps,
)(RiverList);
