import { IconButton, Icon, Tooltip } from "@material-ui/core";
import React, { useState } from "react";
import PrimaryAccordionSummary from "../../customComponents/expansion/PrimaryAccordionSummary";
import SeparatedAccordion from "../../customComponents/expansion/SeparatedAccordion";
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

interface ICustomAccordionProps <T extends { [key: string] : any, id : string, isActive: boolean }> {
    title : string;
    item : T;
    isLoading ?: boolean
    onViewClick ?: (row: T) => void;
    onEditClick ?: (row: T) => void;
    onDeleteClick ?: (row: T) => void;
    accordionDetails : React.ReactElement;
}

const CustomAccordion = <T extends { [key : string] : any, id : string, isActive: boolean }>(props : ICustomAccordionProps<T>) : React.ReactElement => {
    const [expanded, setExpanded] = useState(false);

    function handleViewClick(event : React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        event.stopPropagation();
        return props.onViewClick ? props.onViewClick(props.item) : null;
    };

    function handleEditClick(event : React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        event.stopPropagation();
        return props.onEditClick ? props.onEditClick(props.item) : null;
    };

    function handleDeleteClick(event : React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        event.stopPropagation();
        return props.item.isActive && props.onDeleteClick ? props.onDeleteClick(props.item) : null;
    };

    return (
        <SeparatedAccordion key={props.item.key} expanded={expanded} onChange={() => setExpanded(!expanded)}>
            <PrimaryAccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div className='fdr jcc aic flx1'>
                    {
                        props.title 
                    }
                    <span className='flx1' />
                    {
                        !!props.onViewClick &&
                        <div className='mr15'>
                            <Tooltip title='View'>
                                <IconButton
                                    color='inherit'
                                    value={props.item.id}
                                    onClick={handleViewClick}
                                    size='small'
                                >
                                    <Icon>visibility</Icon>
                                </IconButton>
                            </Tooltip>
                        </div>
                    }
                    {
                        !!props.onEditClick &&
                        <div>
                            <Tooltip title='Edit'>
                                <IconButton color='inherit' size='small' onClick={handleEditClick} aria-label='Edit' disabled={props.isLoading}>
                                    <Icon>edit</Icon>
                                </IconButton>
                            </Tooltip>
                        </div>
                    }
                    {
                        !!props.onDeleteClick &&
                        <div>
                            <Tooltip title='Delete'>
                                <div>
                                    <IconButton className={`${props.item.isActive ? 'cr' : expanded ? 'cg1' : 'cg4'}`} onClick={handleDeleteClick} >
                                        <DeleteIcon />
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </div>
                    }
                </div>
            </PrimaryAccordionSummary>
            {
                props.accordionDetails
            }
        </SeparatedAccordion>
    )
}

export default CustomAccordion