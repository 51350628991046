import { Dialog, AppBar, Toolbar, Typography, IconButton, Icon, CircularProgress, LinearProgress } from '@material-ui/core';
import * as React from 'react';
import { useMemo } from 'react';
import CustomTooltip from '../Tooltip';
import { CustomMouseEvent } from '../../../@types/helper';

interface IProjectDialogProps {
    isOpen : boolean;
    isLoadingCircular ?: boolean;
    isLoadingLinear ?: boolean;
    onClose ?: () => void;
    onConfirm ?: () => void;
    title ?: string;
    fullScreen ?: boolean;
    maxWidth ?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    fullWidth ?: boolean;
    className ?: string;
    children ?: React.ReactNode;
    disableBackdropClick ?: boolean;
    headerColour ?: 'green' | 'red' | 'orange';
    disableClose ?: boolean;
    closeToolTip ?: string;
    disableConfirm ?: boolean;
    confirmToolTip ?: string;
}

/**
 * @param {boolean} isOpen - Determines if the dialog is open or closed. Required.
 * @param {boolean} isLoadingCircular - Circular loading indicator inside the dialog when true. Optional.
 * @param {boolean} isLoadingLinear - Linear loading indicator at the top of the dialog when true. Optional.
 * @param {() => void} onClose - Callback function to be invoked when the dialog is closed. Optional.
 * @param {() => void} onConfirm - Callback function to be invoked when the dialog's confirmation check is clicked. Optional.
 * @param {string} title - Text to be displayed in the dialog header. Optional.
 * @param {boolean} fullScreen - Dialog will be displayed in full-screen mode. Optional.
 * @param {false | 'xs' | 'sm' | 'md' | 'lg' | 'xl'} maxWidth - Sets the maximum width for the dialog. Optional.
 * @param {boolean} fullWidth - Dialog will take up the full width of its container. Optional.
 * @param {string} className - Additional scss classname(s) to apply to the dialog component. Optional.
 * @param {React.ReactNode} children - Content to be rendered inside the dialog. Optional.
 * @param {boolean} disableBackdropClick - Disables closing the dialog by clicking on the backdrop. Optional.
 * @param {'green' | 'red' | 'orange'} headerColour - Color of the dialog header, defaults to green if not used. Optional.
 * @param {boolean} disableClose - Disables the close functionality of the dialog. Optional.
 * @param {string} closeToolTip - Tooltip text for the close button. Optional.
 * @param {string} disableConfirm - Disables the confirm functionality of the dialog. Optional.
 * @param {string} confirmToolTip - Tooltip text for the confirm button. Optional.
 *
 * @return {React.ReactElement} The React element for the Project Dialog component.
 */
const ProjectDialog = (props : IProjectDialogProps) : React.ReactElement => {

    const handleClose = (event : CustomMouseEvent, reason : string) : void => {
        if (reason && reason === 'backdropClick' && props.disableBackdropClick) 
            return;
        props.onClose;
    };

    const headerColour = useMemo(() => {
        const headerColourOptions = {
            green: 'bcp',
            red: 'bcr',
            orange: 'bco',
        };

        return headerColourOptions[props.headerColour ?? 'green'];

    }, [props.headerColour]);

    return (
        <Dialog
            fullWidth={props.fullWidth}
            maxWidth={props.maxWidth || undefined}
            open={props.isOpen}
            onClose={handleClose}
            className={`fdc ${props.className}`}
            fullScreen={props.fullScreen}
        >
            <AppBar position='static' elevation={0}>
                <Toolbar variant='dense' className={`fdr ${headerColour}`}>
                    <div className={'flx3'}>
                        <Typography color='inherit' variant='h6'>{props.title?.toUpperCase()}</Typography>
                    </div>
                    {
                        props.onConfirm && !props.disableConfirm &&
                        <div className={`fdr jcfe aic ${props.fullScreen ? '' : 'pt10 pb10'}`}>
                            <CustomTooltip title={props.confirmToolTip ?? ''}>
                                <IconButton className={'cw jcfe'} onClick={props.onConfirm} disabled={props.disableConfirm}>
                                    <Icon>done</Icon>
                                </IconButton>
                            </CustomTooltip>
                        </div>
                    }
                    {
                        props.onClose &&
                        <div className={`fdr jcfe aic ${props.fullScreen ? '' : 'pt10 pb10'}`}>
                            <CustomTooltip title={props.closeToolTip ?? ''}>
                                <IconButton className={'cw jcfe'} onClick={props.onClose} disabled={props.disableClose}>
                                    <Icon className={'cw'}>close</Icon>
                                </IconButton>
                            </CustomTooltip>
                        </div>
                    }
                </Toolbar>
            </AppBar>
            {
                props.isLoadingCircular &&
                <div className={'posa post90 posr0 posl0 aic jcc zi1000'}>
                    <div className={'posr aic jcc h50 w50'}>
                        <div className={'posa post0 posr0 posb0 posl0 aic jcc'}>
                            <img height={40} src={`${ASSET_BASE}/assets/images/logo.png`} />
                        </div>
                        <CircularProgress color={'primary'} className={'posa post10 posr0 posb0 posl0 z2'} size={50} />
                    </div>
                </div>
            }
            {
                props.isLoadingLinear &&
                <LinearProgress />
            }
            {props.children}
        </Dialog>
    );
};

export default ProjectDialog;
