import { Tooltip } from '@material-ui/core';
import React, { ReactNode } from 'react';

interface CustomTooltipProps {
    onClick ?: () => void;
    children : ReactNode;
    title : string;
    placement ?: 'bottom' | 'top' | 'left' | 'right';
    arrow ?: boolean;
    enterDelay ?: number;
    leaveDelay ?: number;
    className ?: string;
    disableInteractive ?: boolean;
    rightValidation ?: boolean;
}

/**
 * @param {() => void} onClick - Callback function that is invoked when the tooltip is clicked. Optional.
 * @param {React.ReactNode} children - Content inside the tooltip. Required.
 * @param {string} title - Text to be displayed as the tooltip's content. Required.
 * @param {'bottom' | 'top' | 'left' | 'right'} placement - Position of the tooltip relative to the target. Optional.
 * @param {boolean} arrow - Shows an arrow pointing to the tooltip's target if true. Optional.
 * @param {number} enterDelay - Delay in milliseconds before the tooltip appears on hover. Optional.
 * @param {number} leaveDelay - Delay in milliseconds before the tooltip disappears after mouse out. Optional.
 * @param {string} className - Custom CSS class for additional styling. Optional.
 * @param {boolean} disableInteractive - Disables the ability for the tooltip to be interactive. Optional.
 * @param {boolean} rightValidation - Specifies if the tooltip should validate something to the right, typically used in forms. Optional.
 *
 * @return {React.ReactElement} The React element for the Custom Tooltip component.
 */
const CustomTooltip = (props : CustomTooltipProps) : React.JSX.Element => {
    const {
        children,
        title,
        placement = 'top',
        arrow = true,
        enterDelay = 0,
        leaveDelay = 0,
        className = '',
        rightValidation = false,
    } = props;

    const onClick = () : void => {
        if (!props.onClick || !rightValidation) return;
        props.onClick();
    };

    return (
        <Tooltip
            title={!title.includes('\n') ? title : <span style={{ whiteSpace: 'pre-line' }}>{title}</span>}
            placement={placement}
            arrow={arrow}
            enterDelay={enterDelay}
            leaveDelay={leaveDelay}
            onClick={onClick}
        >
            <div className={className}>{children}</div>
        </Tooltip>
    );
};

export default CustomTooltip;
