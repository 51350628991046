import firebase from 'firebase/app';
import firebaseApp, { FirebaseService } from '../../../services/firebaseService';
import { IUserSession } from '../../employee';
import { IDepricatedBaseClass } from '../base';
import { IFileMetaData } from '../files/fileMetaData';

export interface IRiver extends IDepricatedBaseClass<IRiver> {
    GUID : string;
    Division : string | null;
    DivisionDescriptionAccpac : string | null;
    DivisionNumber : number | null;
    ManagementArea : string | null;
    RiverName : string;
    WRNumber : string;
    RiverImageName : string | null;
    RiverImageThumbnailUrl : string | null;
    RiverImageUrl : string | null;
    LatestFlowType : string | null;
    LatestFlow : number | null;
    LatestFlowMeasure : string | null;
    FlowMonitoringStatus : boolean;
    Geom ?: firebase.firestore.GeoPoint | null;
    Elevation : number | null;
    Vnotch : Array<IRiverVNotch>;
    IsActive ?: boolean | null;
    IsSent : boolean;
}

export interface IRiverVNotch {
    height : number;
    flow : number;
}

export default class RiverHelper {
    public static readonly COLLECTION_NAME = 'river';

    public static readonly FLOW_TYPES = [
        'Dry',
        'Low Flow',
        'Medium Flow',
        'High Flow',
        'Flood Flow',
    ];

    private static converter : firebase.firestore.FirestoreDataConverter<IRiver> = {
        fromFirestore: (snapshot) => {
            return RiverHelper.fromFirestoreDocument(snapshot);
        },
        toFirestore: (data : IRiver) : firebase.firestore.DocumentData => {
            return RiverHelper.toFirestoreDocument(data);
        },
    };

    public static fromFirestoreDocument(snapshot : firebase.firestore.DocumentSnapshot) : IRiver {
        const data = snapshot.data() as IRiver | undefined;

        if (!data) {
            throw new Error(`Document does not exist! ${snapshot.id}`);
        }

        const result : IRiver = { 
            ...data,
            ref: snapshot.ref.withConverter(RiverHelper.converter),
            IsActive: !!(typeof data.IsActive === 'undefined' || data.IsActive),
            Vnotch: typeof data.Vnotch === 'undefined' ? [] : data.Vnotch,
        };

        return result;
    }

    public static toFirestoreDocument(data : IRiver) {
        const { ref: _ref, ...firestoreObject } = data;
        return firestoreObject;
    }

    public static doc(id : string) {
        return firebaseApp.firestore().collection(this.COLLECTION_NAME).withConverter(this.converter).doc(id);
    }

    public static listen() {
        return firebaseApp.firestore().collection(this.COLLECTION_NAME).withConverter(this.converter);
    }

    public static async create(river : IRiver) {
        const doc = await river.ref.get();

        if (doc.exists) {
            throw new Error(`${river.WRNumber} already exists.`);
        }

        return river.ref.set(river);
    }

    public static update(borehole : IRiver) {
        return borehole.ref.set(borehole, {
            merge: true,
        });
    }

    public static delete(id : string, session : IUserSession) {
        return firebaseApp
            .firestore()
            .collection(this.COLLECTION_NAME)
            .doc(id)
            .update({
                UpdatedOn: firebase.firestore.Timestamp.now(),
                UpdatedBy: firebaseApp.firestore().collection('employee').doc(session.firebaseUser.uid),
                UpdatedByEmployee: session.employee.EmployeeNumber ?? '',
                UpdatedByName: session.employee.Name,
                IsActive: false,
                IsSent: false,
            } as IRiver);
    }

    public static activate(id : string, session : IUserSession) {
        return firebaseApp
            .firestore()
            .collection(this.COLLECTION_NAME)
            .doc(id)
            .update({
                UpdatedOn: firebase.firestore.Timestamp.now(),
                UpdatedBy: firebaseApp.firestore().collection('employee').doc(session.firebaseUser.uid),
                UpdatedByEmployee: session.employee.EmployeeNumber ?? '',
                UpdatedByName: session.employee.Name,
                IsActive: true,
                IsSent: false,
            } as IRiver);
    }

    public static uploadImage(river : IRiver, file : File, metadata : IFileMetaData, progressCallback : (progress : number) => void, errorCallback ?: (e : Error) => void) {
        const unsub = FirebaseService.fileUpload(file, `river/${river.WRNumber}/${new Date().valueOf()}_${file.name}`, metadata)
            .on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
                const progress = snapshot.bytesTransferred / snapshot.totalBytes * 100;

                if (progress >= 100) {
                    unsub();
                }

                progressCallback(progress);
            }, errorCallback);
    }
}