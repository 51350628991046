import { AccordionSummary, withStyles } from '@material-ui/core';

const PrimaryAccordionSummary = withStyles((theme) => ({
    root: {
        color: theme.palette.primary.main,

        '&$expanded': {
            color: theme.palette.secondary.main,
            backgroundColor: theme.palette.primary.main,
        },

        '& .MuiAccordion-rounded': {
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
            borderBottomLeftRadius: 4,
            borderBottomRightRadius: 4,
        },
        ':before': {
            display: 'none',
        },
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
    },
    expanded: {
        color: theme.palette.secondary.main,
    },
}))(AccordionSummary);

export default PrimaryAccordionSummary;