import React from 'react';
import { CustomChangeEvent } from '../../@types/helper';
import { useField } from 'formik';
import { CheckboxProps } from '@material-ui/core';
import LabelledCheckbox from './LabelledCheckbox';

export interface IFormCheckboxProps {
    label ?: string;
    name : string;
    checkboxProps ?: CheckboxProps;
    onChange ?: (e : CustomChangeEvent, checked : boolean) => void;
    className ?: string;
    disabled ?: boolean;
}

const FormCheckbox = (props : IFormCheckboxProps) : React.ReactElement => {
    const [field, meta, helper] = useField(props.name);

    const onChange = (e : CustomChangeEvent, checked : boolean) : void => {
        helper.setValue(checked);
        if (props.onChange) props.onChange(e, checked);
    };

    return (
        <LabelledCheckbox
            {...props}
            value={field.value}
            onChange={onChange}
            error={meta.error}
        />
    );
};

export default FormCheckbox;
