import React from 'react';
import { CustomChangeEvent } from '../../@types/helper';
import { Checkbox, CheckboxProps, FormControlLabel, FormHelperText } from '@material-ui/core';

export interface ILabelledCheckboxProps {
    label ?: string;
    name : string;
    checkboxProps ?: CheckboxProps;
    onChange : (e : CustomChangeEvent, checked : boolean) => void;
    size ?: 'small' | 'medium';
    className ?: string;
    disabled ?: boolean;
    value : boolean;
    error ?: string;
}

const LabelledCheckbox = (props : ILabelledCheckboxProps) : React.ReactElement => {

    const onChange = (e : CustomChangeEvent, checked : boolean) : void => {
        props.onChange(e, checked);
    };

    const {
        checkboxProps,
        label,
        className,
        disabled,
        value,
        name,
        size,
    } = props;

    return (
        <div className={`pl10 pr10 fdc ais jcfs ${className || ''}`}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={value}
                        id={name}
                        name={name}
                        color={'primary'}
                        onChange={onChange}
                        disabled={disabled}
                        className={className}
                        size={size}
                        {...checkboxProps}
                    />
                }
                label={label || ''}
            />
            {
                props.error &&
                <FormHelperText error>{props.error || ''}</FormHelperText>
            }
        </div>
    );
};

export default LabelledCheckbox;
