import React, { useEffect, useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import lodash from 'lodash';
import { IOptionType } from '../../@types/model/optionType';

export type DropdownValueType = IOptionType | Array<IOptionType> | null;

export interface IDropdownSelectProps {
    name : string;
    label : string;
    options : Array<IOptionType>;
    value : DropdownValueType;
    onChange : (v : DropdownValueType) => void;
    isLoading : boolean; 
    disabled ?: boolean;
    defaultValue?: IOptionType | null;
}

const CustomDropdown = (props : IDropdownSelectProps) : React.ReactElement => {
    const [value, setValue] = useState<IOptionType | null>(props.defaultValue ?? null);

    useEffect(() => {
        if(props.defaultValue) setValue(props.defaultValue);
    }, [props.defaultValue]);

    const onChanged = (event : React.ChangeEvent<{
        name ?: string | undefined;
        value : unknown;
    }>) => {

        const internalValue = event.target.value;
        const option = props.options.find(x => x.value === internalValue);

        if (typeof internalValue !== 'string' || !option) return;

        props.onChange(option);
        setValue(option);
    };
    return (
        <div className='fdr flx1 aifs'>
            <div className={'flx1 aifs pr10'}>
                <FormControl fullWidth>
                    <InputLabel htmlFor={props.name}>{props.name}</InputLabel>
                    <Select
                        disabled={props.isLoading}
                        fullWidth
                        id={props.name}
                        value={value ? value.label : ''}
                        onChange={onChanged}>
                        <MenuItem value=''>
                            <em>None</em>
                        </MenuItem>
                        {
                            lodash.map(props.options, (n, i) => (
                                <MenuItem key={`${i}_${props.name}`} value={`${n.label}`}>
                                    {n.label}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </div>
        </div>
    );
};

export default CustomDropdown;