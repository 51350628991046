import React, { useEffect, useMemo, useState } from 'react';
import { AppBar, Dialog, DialogContent, Icon, IconButton, Toolbar, Tooltip, Typography } from '@material-ui/core';
import { IUserSession } from '../../../@types/employee';
import GeneralFunctions from '../../../store/general/functions';
import { Transitions } from '../../customComponents/animations/Transitions';
import { IRiver } from '../../../@types/model/river/river';
import RiverModelHelper from '../../../@types/model/river/riverModelHelper';
import RiverInfoEditForm from '../form/RiverInfoForm';
import useFirestoreCollection from '../../../@types/hooks/firestore/useFirestoreCollection';
import { IDivision } from '../../../@types/model/masterData/division';
import RiverDivisionModelHelper from '../../../@types/model/masterData/DivisionModelHelper';
import { getState } from '../../../store';
import { IRiverFormValues } from '../../../@types/model/river/riverFormValues';

interface IRiverInfoEditDialogProps {
    selectedRiver ?: IRiver | null;
    isOpen : boolean;
    onDismiss : () => void;
}

const RiverInfoEditDialog = (props : IRiverInfoEditDialogProps) => {
    const currentUser = getState().auth.session;
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { data: divisions, isLoading : isLoadingDivisions } = useFirestoreCollection<IDivision>(RiverDivisionModelHelper.collection());

    /*================================================================================================================
     *                                                      Effects
     * ==============================================================================================================*/

    /*================================================================================================================
    *                                                  Handler Methods
    * ==============================================================================================================*/

    const onSubmit = async (formValues : IRiverFormValues) : Promise<void> => {
        setIsLoading(true);

        if (!currentUser) {
            setIsLoading(false);
            return;
        }

        const river = RiverModelHelper.createSave(currentUser, formValues, props.selectedRiver);

        try {
            await RiverModelHelper.save(river);
            setIsLoading(false);
            GeneralFunctions.generalShowSuccessSnackbar('River info updated successfully.');
            props.onDismiss();

        } catch (e) {
            setIsLoading(false);
        }
    };

    /*================================================================================================================
    *                                                  Memos
    * ==============================================================================================================*/

    const getInitialFormValues = useMemo<IRiverFormValues | Array<IRiverFormValues>>(() => {
        if (!currentUser) return [];
        return RiverModelHelper.createFormValues(currentUser, props.selectedRiver);
    }, [divisions, currentUser, props.selectedRiver]); 

    /*================================================================================================================
    *                                                  Render
    * ==============================================================================================================*/

    return (
        <>
            <Dialog
                open={props.isOpen}
                TransitionComponent={Transitions.Up}
                transitionDuration={500}
                onClose={props.onDismiss}
                maxWidth={'sm'}
                aria-labelledby='river-edit-dialog-title'
                aria-describedby='river-edit-description'>
                <AppBar className='fdr posr aic jcc' position='static'>
                    <Toolbar className={'fdr flx1 aic jcc'}>
                        <Typography variant='h5' color='inherit'>
                            { props.selectedRiver ? 'Edit River Info' : 'Add River Info'}
                        </Typography>
                        <span className='flx1' />
                        <Tooltip title='Close'>
                            <div>
                                <IconButton color='inherit' disabled={isLoading} onClick={props.onDismiss} aria-label='Close'>
                                    <Icon>close</Icon>
                                </IconButton>
                            </div>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                    <DialogContent className='fdc flx1 jcfs hfill'>
                        <RiverInfoEditForm 
                            initialValues={getInitialFormValues} 
                            onSubmit={onSubmit} 
                            onCancel={props.onDismiss}
                            divisions={divisions}
                            isLoading={isLoading}
                        />
                    </DialogContent>
            </Dialog>
        </>
    );
};

export default RiverInfoEditDialog;