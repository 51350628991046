import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomTextField from '../../customComponentV2/CustomTextField';
import CustomLabelledCheckBox from '../../customComponentV2/CustomCheckBox';
import { Button } from '@material-ui/core';
import RiverModelHelper from '../../../@types/model/river/riverModelHelper';
import CustomControlledDropdown from '../../customComponentV2/CustomControlledDropdown';
import { IOptionType } from '../../../@types/model/optionType';
import { IDivision } from '../../../@types/model/masterData/division';
import DivisionModelHelper from '../../../@types/model/masterData/DivisionModelHelper';
import { IRiverFormValues } from '../../../@types/model/river/riverFormValues';

interface IRiverInfoEditFormProps {
    initialValues : IRiverFormValues;
    divisions : Array<IDivision>;
    isLoading : boolean;
    onSubmit : (values : IRiverFormValues) => void;
    onCancel : () => void;
}

const RiverInfoEditForm = (props : IRiverInfoEditFormProps) : React.ReactElement => {
    const [convertedDivisions, setConvertedDivisions] = useState<Array<IOptionType>>([]);

    const schema = RiverModelHelper.formSchema();
    const defaultValue : IOptionType | null = props.initialValues.Division 

    /*================================================================================================================
    *                                                      Effects
    * ==============================================================================================================*/

    useEffect(() => {
        setConvertedDivisions(DivisionModelHelper.convertToOptionType(props.divisions));
    }, [props.divisions]);

    /*================================================================================================================
    *                                                  Handler Methods
    * ==============================================================================================================*/

    const { handleSubmit, control, formState: { isValid } } = useForm<IRiverFormValues>({
        defaultValues: props.initialValues,
        resolver: yupResolver(schema),
        mode: 'onChange',
    });

    /*================================================================================================================
    *                                                  Memos
    * ==============================================================================================================*/

    /*================================================================================================================
    *                                                      Render
    * ==============================================================================================================*/

    return (
        <>
            <form onSubmit={handleSubmit(props.onSubmit)}>
                <div className={'fdc p30 pt15 pb0'}>
                    <div className={'fdc wfill pt10 jcc aic mb20'}>
                        <div className={'flx1 wfill mb15'}>
                            <CustomTextField 
                                className={'wfill'}
                                label={'Name'}
                                name={'RiverName'}
                                control={control}
                            />
                        </div>
                        <div className={'flx1 wfill mb15'}>
                            <CustomControlledDropdown 
                                label={'Division'}
                                name={'Division'}
                                control={control}
                                options={convertedDivisions}
                                disabled={props.isLoading}
                                defaultValues={defaultValue}
                            />
                        </div>
                    </div>
                    <div className='flx1 fdr jcfs'>
                        <CustomLabelledCheckBox
                            name={'FlowMonitoringStatus'}
                            label={'Flow Monitoring Status'}
                            control={control}
                            defaultValue={props.initialValues.FlowMonitoringStatus ?? false}
                        />
                    </div>
                    <div className={'fdr flx1 aic jcfe mb20'}>
                        <div className={'jcfe'}>
                            <Button className='m10' disabled={!isValid} type='submit' variant='contained' color='primary'>
                                OK
                            </Button>
                            <Button className='m10' variant='outlined' onClick={props.onCancel} color='primary' autoFocus>
                                CANCEL
                            </Button>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
};

export default RiverInfoEditForm;