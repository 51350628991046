import React from 'react';
import { IUserSession } from '../../../@types/employee';
import Typography from '@material-ui/core/Typography';
import FirebaseStorageImage from '../../customComponents/image/FirebaseStorageImage';
import CarouselImageViewer from '../../customComponents/image/CarouselViewer';
import { Transitions } from '../../customComponents/animations/Transitions';
import EditRiverInfo from '../dialog/EditInfo';
import EditRiverImages from '../dialog/EditImages';
import riverFunctions from '../../../store/river/functions';
import EditLocationDialog from '../../customComponents/dialog/LocationEditDialog';
import { RIVER_MAP_IMAGES } from '../../../appConstants';
import { AppBar, Card, Icon, IconButton, Paper, Toolbar, Tooltip as MUITooltip } from '@material-ui/core';
import { IRiver } from '../../../@types/model/river/river';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import EditRiverVNotchDialog from '../dialog/EditVNotchDialog';
import RiverMonitorTestYieldsGraph from './YieldsGraph';
import RiverInfoEditDialog from '../dialog/RiverInfoEditDialog';

interface IRiverDetailViewProps {
    river : IRiver;
    session : IUserSession;
}

interface IRiverDetailViewState {
    imageSrcs : Array<string>;
    isCarouselOpen : boolean;
    imageIndex : number;
    isEditDialogOpen : boolean;
}

export default class RiverDetailView extends React.Component<IRiverDetailViewProps, IRiverDetailViewState> {
    constructor(props : IRiverDetailViewProps) {
        super(props);
        this.state = {
            imageSrcs: [],
            isCarouselOpen: false,
            imageIndex: 0,
            isEditDialogOpen: false
        };
    }

    private readonly onImageUrlLoaded = (url : string) => {
        const imageSrcs = this.state.imageSrcs.slice();
        const index = imageSrcs.indexOf(url);

        if (index > 0) {
            imageSrcs.splice(index, 1, url);
        } else {
            imageSrcs.push(url);
        }

        this.setState({
            imageSrcs,
        });
    };

    private readonly onThumbClick = (url : string) => {
        const imageSrcs = this.state.imageSrcs.slice();
        const index = imageSrcs.indexOf(url);

        this.setState({
            isCarouselOpen: true,
            imageIndex: index,
        });
    };

    private readonly onCarouselClose = () => {
        this.setState({
            isCarouselOpen: false,
        });
    };

    private readonly onEditClicked = () => {
        this.setState({
            isEditDialogOpen: true,
        });
    };

    private readonly onEditDialogClose = () => {
        this.setState({
            isEditDialogOpen: false,
        });
    };

    private readonly onLocationSave = async (geom : firebase.firestore.GeoPoint, elevation : number) => {
        const river = Object.assign({}, this.props.river);

        river.Geom = geom;
        river.Elevation = elevation;

        await riverFunctions.saveRiver(river);
    };

    public readonly render = () => {
        const { river, session } = this.props;
        const { imageSrcs, isCarouselOpen, imageIndex } = this.state;

        return (
            <div className={'fdr  bcg0 hfill oyh'}>
                <div className={'flx1 fdc p30'}>
                    <div className=''>
                        <Paper className='mt20 fdc flx2' elevation={3}>
                            <AppBar position='static' className='pbr' elevation={0}>
                                <Toolbar variant='dense'>
                                    <Typography variant='h6' className='flx1 cw'>
                                        RIVER INFO
                                    </Typography>
                                    <span className={'flx1'} />
                                    {
                                        session.employee.IsRiverAdmin &&
                                        <MUITooltip title={'Edit'}>
                                            <div>
                                                <IconButton color='inherit' size='small' onClick={this.onEditClicked} aria-label='Edit'>
                                                    <Icon>edit</Icon>
                                                </IconButton>
                                            </div>
                                        </MUITooltip>
                                    }
                                    {
                                        session.employee.IsRiverAdmin && this.state.isEditDialogOpen &&
                                        <RiverInfoEditDialog selectedRiver={river} onDismiss={this.onEditDialogClose} isOpen={this.state.isEditDialogOpen}/>
                                    }
                                </Toolbar>
                            </AppBar>
                            <div className={'fdr'}>
                                <div className={'fdc flx1 p30'}>
                                    <Typography className={'fs12 dcgrey'}>
                                        Code
                                    </Typography>
                                    <Typography className={' fs16 pt10'}>
                                        {river.WRNumber}
                                    </Typography>
                                </div>
                                <div className={'fdc flx1 p30'}>
                                    <Typography className={'fs12 mw160 dcgrey'}>
                                        Name
                                    </Typography>
                                    <Typography className={'fs16 pt10'}>
                                        {river.RiverName}
                                    </Typography>
                                </div>
                                <div className={'fdc flx1 p30'}>
                                    <Typography className={'fs12 mw160 dcgrey'}>
                                        Division
                                    </Typography>
                                    <Typography className={'fs16 pt10'}>
                                        {river.Division}
                                    </Typography>
                                </div>
                                <div className={'fdc p30'}>
                                    <Typography className={'fs12 mw160 dcgrey'}>
                                        Flow monitoring status
                                    </Typography>
                                    <Typography className={'fs16 pt10'}>
                                        {river.FlowMonitoringStatus ? 'Monitoring' : 'Not Monitoring'}
                                    </Typography>
                                </div>
                            </div>
                        </Paper>
                    </div>
                    <div className=''>
                        <Paper className='mt20 fdc flx2' elevation={3}>
                            <AppBar position='static' className='pbr bcdlg3' elevation={0}>
                                <Toolbar variant='dense'>
                                    <Typography variant='h6' className='flx1 cw'>
                                        LOCATION
                                    </Typography>
                                    <span className={'flx1'} />
                                    {
                                        session.employee.IsRiverAdmin &&
                                        <EditLocationDialog
                                            markerImage={RIVER_MAP_IMAGES['1']}
                                            markerImageSize={0.35}
                                            elevation={river.Elevation ?? 0}
                                            geom={river.Geom}
                                            transition={Transitions.Up} maxWidth='sm' fullWidth
                                            onSave={this.onLocationSave}
                                        />
                                    }
                                </Toolbar>
                            </AppBar>
                            <div className={'fdr'}>
                                <div className={'fdc flx1 p30'}>
                                    <Typography className={'fs12 dcgrey'}>
                                        Latitude
                                    </Typography>
                                    <Typography className={' fs16 pt10'}>
                                        {river.Geom?.latitude.toFixed(8) ?? 'None'}
                                    </Typography>
                                </div>
                                <div className={'fdc flx1 p30'}>
                                    <Typography className={'fs12 mw160 dcgrey'}>
                                        Logitude
                                    </Typography>
                                    <Typography className={'fs16 pt10'}>
                                        {river.Geom?.longitude.toFixed(8) ?? 'None'}
                                    </Typography>
                                </div>
                                <div className={'fdc flx1 p30'}>
                                    <Typography className={'fs12 mw160 dcgrey'}>
                                        Elevation
                                    </Typography>
                                    <Typography className={'fs16 pt10'}>
                                        {!river.Elevation ? 'None' : river.Elevation}
                                    </Typography>
                                </div>
                            </div>
                        </Paper>
                    </div>
                    <div className=''>
                        <Paper className='mt20 fdc flx2' elevation={3}>
                            <AppBar position='static' className='pbr' elevation={0}>
                                <Toolbar variant='dense'>
                                    <Typography variant='h6' className='flx1 cw'>
                                        FLOW
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                        </Paper>
                    </div>
                    <div className=''>
                        <div className={'fdr flx1 mt20'}>
                            <Card className={'flx1 mr20 dbclb jcc tac fdc p20'}>
                                <Typography className={'cw fs16'}>
                                    Latest Flow
                                </Typography>
                                <Typography className={'cw fs36 fw500'}>
                                    {
                                        !river.LatestFlow ? '-' : `${river.LatestFlow}`
                                    }
                                    {
                                        !river.LatestFlow &&
                                        <span><sup></sup></span>
                                    }
                                    {
                                        !!river.LatestFlow &&
                                        <span> m<sup>3</sup>/h</span>
                                    }
                                </Typography>
                            </Card>
                            <Card className={'flx1 dbcb jcc tac fdc p20'}>
                                <Typography className={'cw fs16'}>
                                    Latest Flow Type
                                </Typography>
                                <Typography className={'cw fs36 fw500'}>
                                    {
                                        river.LatestFlowType ?? '-'
                                    }
                                </Typography>
                            </Card>
                        </div>
                    </div>
                    <div className=''>
                        <div className={'fdr flx1 mt20'}>
                            <Card className={'flx1 dbcdb jcfs fdc p20'}>
                                <Typography className={'cw fwr fs16'}>
                                    Measurement Type
                                </Typography>
                                <Typography className={'cw fs36 fw500'}>
                                    {
                                        river.LatestFlowMeasure ?? '-'
                                    }
                                </Typography>
                            </Card>
                        </div>
                    </div>
                </div>
                <div className={'flx1 fdc p30'}>
                    <div className=''>
                        <Paper className='mt20 fdc flx2' elevation={3}>
                            <AppBar position='static' className='pbr' elevation={0}>
                                <Toolbar variant='dense'>
                                    <Typography variant='h6' className='flx1 cw'>
                                        IMAGE
                                    </Typography>
                                    <span className='flx1' />
                                    {
                                        session.employee.IsRiverAdmin &&
                                        <EditRiverImages river={river} transition={Transitions.Up} maxWidth='sm' fullWidth />
                                    }
                                </Toolbar>
                            </AppBar>
                            <div className={'fdr'}>
                                {
                                    river.RiverImageName &&
                                    <div className='flx1 p10 jcc fdc'>
                                        <FirebaseStorageImage fileName={river.RiverImageName} path={`river/${river.WRNumber}`} onClick={this.onThumbClick} onLoaded={this.onImageUrlLoaded} />
                                    </div>
                                }
                                {
                                    imageSrcs.length > 0 &&
                                    <CarouselImageViewer index={imageIndex} isOpen={isCarouselOpen} imageSrcs={imageSrcs} onClose={this.onCarouselClose} />
                                }
                            </div>
                        </Paper>
                    </div>
                    <div className='fdc flx1'>
                        <Paper className='mt20 fdc flx2' elevation={3}>
                            <AppBar position='static' className='pbr' elevation={0}>
                                <Toolbar variant='dense'>
                                    <Typography variant='h6' className='flx1 cw'>
                                        YIELDS
                                    </Typography>
                                    <span className='flx1' />
                                </Toolbar>
                            </AppBar>
                            <div className={'fdr flx1'}>
                                <RiverMonitorTestYieldsGraph />
                            </div>
                        </Paper>
                    </div>
                    <div className='fdc flx1'>
                    </div>
                </div>
                <div className={'flx1 fdc p30'}>
                    <div className={'fdc flx2 mt20'}>
                        {
                            !river.Vnotch.length &&
                            <Card className={'jcc aic fdc wfill h50 mb50'}>
                                {
                                    session.employee.IsDamAdmin &&
                                    <div className={'fdr jcc aic'}>
                                        <Typography className={'fs15 cp aic jcc'}>
                                            Add V-Notch Data
                                        </Typography>
                                        <EditRiverVNotchDialog river={river} />
                                    </div>
                                }
                            </Card>
                        }
                        {
                            !!river.Vnotch.length &&
                            <Card className={'jcc aic fdc flx2'}>
                                <div className={'fl1 aic fdr'}>
                                    <Typography className={''}>
                                        {river.RiverName}
                                    </Typography>
                                    <EditRiverVNotchDialog river={river} />
                                </div>
                                <ResponsiveContainer width='100%' height='90%'>
                                    <LineChart 
                                        margin={{
                                            top: 0,
                                            right: 50,
                                            bottom: 30,
                                            left: 20,
                                        }}
                                        data={river.Vnotch}>                               
                                        <XAxis
                                            dataKey='height'
                                            allowDecimals={false}
                                            interval={'preserveStart'}
                                            label={{
                                                value: 'HEIGHT (cm)',
                                                position: 'bottom',
                                            }}
                                            type='category'
                                        />
                                        <YAxis
                                            label={{
                                                value: 'FLOW (m³/h)',
                                                position: 'insideLeft',
                                                angle: -90,
                                            }}
                                        />
                                        <CartesianGrid stroke='#eee'/>
                                        <Line type='monotone' dataKey='flow' stroke='#FD9926' dot={false} strokeWidth={3}/>
                                        <Tooltip
                                            formatter={(x) => `${x} m³/h`}
                                            labelFormatter={(x) => `${x} cm`}
                                        />
                                    </LineChart>
                                </ResponsiveContainer>
                            </Card>
                        }
                    </div>
                    <div className={'fdc flx1 mt20'}></div>
                    <div className={'fdc flx1 mt20'}></div>
                </div>
            </div>
        );
    };
}
